import React from 'react'
import { Modal, Alert, Button } from 'react-bootstrap'

export default function DeleteEntityModal({entity, show, onHide}) {

  const handleDelete = (e) => {
    // console.log("DELETE EVENT")
    // console.log(event)
  }
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Supprimer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant='danger'>
            Etes vous sur de vouloir supprimer "<strong>{entity.title}</strong>" ? <br/> La suppression est définitive.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Annuler
        </Button>
        <Button variant="danger" onClick={e => handleDelete(e)}>
          Supprimer
        </Button>
      </Modal.Footer> 
    </Modal>
  )
}
