import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouseUser, faCalendarDays, faCalendarPlus, faMapLocationDot , faBus, faGear, faEnvelope, faUser} from '@fortawesome/free-solid-svg-icons';

import { useRecoilValue, useRecoilState  } from 'recoil'
import { createEventSteps, createEventForm } from 'app/states/event_state'
import { useLocation } from 'react-router-dom';
import { Grid } from 'antd';
import { getUser } from 'app/user_state';
import UserMenu  from 'app/components/UserMenu'
const { useBreakpoint } = Grid

export default function SidebarAdmin({currentPage}) {

  const screens = useBreakpoint();
  const user = getUser()
  console.log(screens);

  const [eventStep, setEventStep] = useRecoilState(createEventSteps);
  const eventForm = useRecoilValue(createEventForm);
  let location = useLocation();
  console.log(location)
  const [page, setPage ] = useState(currentPage)
  // useEffect(() => {
  //   setPage(currentPage)
  //   // console.log("PAGE CHANGED")
  //   // console.log(currentPage);
  // }, [location]);
  

  //console.log("SIDEBAR EVENT STEP ", eventStep);
  const getIconColor = (page) =>{
    return page === location.pathname ? '#6AB50C' : '#D3D3D3'
  }
  return (
    <>
     
      <ul className="admin_sidebar_menu">
        <li style={{display: screens.md ? "inline" : 'block'}}>
          <Link to="/"><img src="/kati360.png" id="logo"/></Link>
        </li>
        <li> <Link to="/user/events"><FontAwesomeIcon icon={faHouseUser} style={{color: getIconColor("/user/events")}} /></Link></li>
        <li> <Link to="/user/messages"><FontAwesomeIcon icon={faEnvelope} style={{color: getIconColor("/user/messages")}} /></Link></li>
        <li> <Link to="/admin/events/generate"><FontAwesomeIcon icon={faCalendarPlus} style={{color: getIconColor("/admin/events/generate")}} /></Link></li>
        <li> <Link to="/admin/events/list"><FontAwesomeIcon icon={faCalendarDays} style={{color: getIconColor("/admin/events/list")}} /></Link></li>
        <li> <Link to="/admin/locations"><FontAwesomeIcon icon={faMapLocationDot} style={{color: getIconColor("/admin/locations")}} /></Link></li>
        <li> <Link to="/admin/transportations"><FontAwesomeIcon icon={faBus} style={{color: getIconColor("/admin/transportations")}} /></Link></li>
        <li> <Link to="/user/profile"><FontAwesomeIcon icon={faUser} style={{color: getIconColor("/user/profile")}} /></Link></li>
        {/* <li> <img src="/icons/megaphone.png" alt="" /> </li> */}
        { !screens.md &&  user && <li><UserMenu user={user}/></li> }
      </ul>      
    </>
  )
}
