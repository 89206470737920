// Import the functions you need from the SDKs you need import 
import { initializeApp } from "firebase/app"; 
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use 
// https://firebase.google.com/docs/web/setup#available-libraries 
// Your web app's Firebase configuration 
const firebaseConfig = { 
  apiKey: "AIzaSyD96BKf53lauFzq_RB2ImpdwB02rFEJOZQ", 
  authDomain: "shs-backend.firebaseapp.com", 
  projectId: "shs-backend", 
  storageBucket: "shs-backend.appspot.com", 
  messagingSenderId: "384587816782", 
  appId: "1:384587816782:web:9a3f0fdc2ff2e2afc9481f" }; 
  // Initialize Firebase 
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
