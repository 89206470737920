
const baseURL = "https://www.eventkati360.com/api/";

const ApiConfig = {
  users: `${baseURL}users`,
  messages: `${baseURL}messages`,
  agents: `${baseURL}agents`,

  types: `${baseURL}types`,

  events: `${baseURL}events/`,
  create_event: `${baseURL}events`,

  orders: `${baseURL}orders/`,
  create_order: `${baseURL}orders`,
  composte_order: `${baseURL}orders/validate`,
  compostage: `${baseURL}validations`,

  tickets: `${baseURL}tickets`,

  categories: `${baseURL}categories`,

  sub_categories: `${baseURL}subcategories/`,

  locations: `${baseURL}locations/`,

  transportations: `${baseURL}transportations/`,
};

export default ApiConfig;
