import Slider from "react-slick";
import { useState, useEffect, useRef } from "react";
import ApiConfig from "app/ApiConfig";
import OrganiseurHomeCard from "app/components/organiseur-home-card/organiseur-home-card";


export default function OrganisateurSlider() {
  const [users, setUsers] = useState([]);
  const sliderRef = useRef()
  useEffect(()=>{
    const fetchUsers = async () => {
      const res = await fetch(ApiConfig.agents);
      const usersRes = await res.json();
      setUsers(usersRes.data);
      document.querySelectorAll('.orga_slider .slick-track').forEach((item)=>{
        item.style.transform = 'translate3d(0px,0px,0px)';
      })
     
    }
    fetchUsers().catch(e=>console.log(e));
  },[])

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 4,
    initialSlide: 0,
    className:"orga_slider",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          initialSlide: 0,
          infinite: false,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
          infinite: false,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          infinite: false,
        }
      }
    ]
  };
  return (
    <Slider ref={sliderRef} {...settings}>
      {users.map((user)=><OrganiseurHomeCard key={user.uid} orga={user}/>)}     
    </Slider>
  );
}