import { useEffect, useState } from 'react'
import { Scanner } from '@yudiel/react-qr-scanner'
import { Modal, Button } from 'react-bootstrap'
import { Spin } from 'antd'

import ApiConfig from 'app/ApiConfig'
import SuccessCheck from '../success-check/SuccessCheck'
import ErrorCheck from '../error-check/ErrorCheck'
import { getUser } from 'app/user_state'

export default function QrCodeModal({show, onHide, handleSave}) {

  const user = getUser();

  const [isScanning, setIsScanning] = useState(true)
  const [scanResponse, setScanResponse] = useState({})
  const [scanReq, setScanReq] = useState(null)
  const [ isLoading, setIsLoading ] = useState(false);

  const handleQRCodeResult = (result, data) => { 
    //alert(result, data)
    setScanReq(JSON.parse(result));
    //
    setIsScanning(false);
    setIsLoading(true);
    //
    
  }

  const openScan = (e)=>{
    setIsScanning(true)
    setScanResponse({})
  }

  const hideScan = (e) => {
    setScanResponse({})
    onHide()
  }

  useEffect(()=>{
    const submitCompost = async () => {
      const compostReq = await fetch(ApiConfig.composte_order, {
        method: "POST",
        body: JSON.stringify({...scanReq}),
        headers: {
          'Content-Type': 'application/json'
        }
      })
      const compostRes = await compostReq.json();
      setScanResponse(compostRes)
      setIsLoading(false);
      console.log(compostRes)
    }

    submitCompost().catch(e=>console.log(e))
  },[scanReq])

  return (
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Composter le ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body className={'compostModalBody'}>
          {isScanning && (
            <Scanner
              onResult={handleQRCodeResult}
              onError={(error) => alert("ERROR",error?.message)}
              />
          )}
          {!isScanning && <Button className="btn btn-primary" onClick={openScan}>Scanner</Button>}         
          {/* {(scanResponse?.error) && <ErrorCheck />} */}
          {(scanResponse?.success == false) && <ErrorCheck />}
          {(scanResponse?.success == true) && <SuccessCheck />}
            
          
          {/* { Object.keys(scanResponse).length > 0 && (
              (scanResponse.success  && <SuccessCheck /> ) ||
              (scanResponse.error && <ErrorCheck /> )             
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideScan}>
            Close
          </Button>
          <Button variant="primary" onClick={e => handleSave(e)}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
  )
}
