import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styles from './list.module.css';
import 'app/admin.css'

import ApiConfig from 'app/ApiConfig';



import NavbarAdmin from 'app/components/NavbarAdmin';
import SidebarAdmin from 'app/components/SidebarAdmin';

import { Button, Modal, Dropdown } from 'react-bootstrap';
import { Table } from 'antd';
import DeleteEntityModal from 'app/components/DeleteEntityModal';
import TransportationFormModal from './TransformationFormModal';
import CreateTransportationModalContent from './CreateTransportationModalContent';

export default function Transportations () {
  // SEARCH
  const [nameSearch, setNameSearch] = useState("");
  const [dateStartSearch, setDateStartSearch] = useState("");
  const [dateEndSearch, setDateEndSearch] = useState("");
  const [columns, setColumns] = useState([]);
  const [tickets, setTickets] = useState({});

  // CRUD MODAL
	const [showCreateTransportationModal, setShowCreateTransportationModal] = useState(false);
  
  // CREATE
  const openCreateTransportationModal = (e) => {
    //e.preventDefault();
    setCurrentTransport(null);
    setShowCreateTransportationModal(true)
  }
  // DELETE
  const [showDeleteEntityModal, setShowDeleteEntityModal] = useState(false)
  const [toDeleteEntity, setToDeleteEntity] = useState({});
  const openDeleteEntityModal = (e, record) => {
    e.preventDefault();
    setToDeleteEntity(record);
    setShowDeleteEntityModal(true);
  }
  const onHideDeleteEntityModal = (e) => {
    setShowDeleteEntityModal(false)
  }
  // UPDATE
  const [currentTransport, setCurrentTransport] = useState({});
  const openUpdateEventModal = (e, record) =>{
    setCurrentTransport(record);  
    setShowCreateTransportationModal(true);
  }

  
  // get column
  useEffect(()=>{
    const columns_builder = [
      {       
        title: "Nom transport",
        render: (_,row) => {          
          return row.name
        }
      }, 
      {
        title: 'Description',
        render: (_,row) => row.description,
      }
      , 
      {
        title: 'Action',
        render: (_,row) => (
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            Paramètres
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={e=>openUpdateEventModal(e, row)}>Modifier</Dropdown.Item>
            <Dropdown.Item onClick={e=>openDeleteEntityModal(e, {...row, title: row['name']})}>Supprimer</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        ),
      }
      
    ];

    setColumns(columns_builder);
  },[])

  useEffect(()=>{
    let filters = {};
    if(nameSearch)
      filters['title'] = nameSearch

    if(dateStartSearch){
      filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
      filters['date_time']['date_start'] = dateStartSearch
    }

    if(dateEndSearch){
      filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
      filters['date_time']['date_end'] = dateEndSearch
    }

    

    console.log('FINALE FILTER')
    console.log(filters);
    console.log(encodeURI(JSON.stringify(filters)))
  },[nameSearch, dateStartSearch, dateEndSearch])



  // DATATABLE
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);

	const fetchTransportations = async page => {
		setLoading(true);
    console.log("CURRENT PER PAGE", perPage)
    let start = (page - 1) * perPage ;
    let end = page * perPage;

		//const response = await fetch(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,{
		const response = await fetch(`${ApiConfig.transportations}?_start=${start}&_end=${end}`,{
      headers:{
        'Content-Type':'application/json'
      }
    });
    const transportations = await response.json();

    console.log(transportations);
		setTotalRows(transportations.total);
		setData(transportations.data);
		setLoading(false);
	};

	const handlePageChange = page => {
		fetchTransportations(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
    //fetchUsers(page);
	};


	useEffect(() => {
		fetchTransportations(page); // fetch page 1 of users
		
	}, [perPage, page]);

	return (
    <>
      <Button className="btn btn-primary" onClick={openCreateTransportationModal}>Ajouter un moyen de transport</Button>       
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        pagination={{
          pageSize: perPage,
          total: totalRows,
          onChange:(page)=>{
            setPage(page)
          }
        }}
      /> 
      <TransportationFormModal 
        showCreateTransportationModal={showCreateTransportationModal} 
        setShowCreateTransportationModal={setShowCreateTransportationModal}
        currentTransport={currentTransport}
      />
       {/*  */}
      <DeleteEntityModal show={showDeleteEntityModal} onHide={onHideDeleteEntityModal} entity={toDeleteEntity}/>
    </>
	);
};
