'use client';

import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styles from '../list.module.css';
import 'app/admin.css'

import ApiConfig from 'app/ApiConfig';
import AppConfig from 'app/AppConfig';

import NavbarAdmin from 'app/components/NavbarAdmin';
import SidebarAdmin from 'app/components/SidebarAdmin';
import QrCodeModal from 'app/components/qrcode-modal/QrCodeModal';
import BilleterieOrder from 'app/components/events/BilleterieOrder';

import { Button , Badge} from 'react-bootstrap';

import { useParams, useNavigate, defer, useLoaderData, Link} from 'react-router-dom';
import { Table , Card} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { getUser } from 'app/user_state';

export const EventListSingleLoader =  async ({params}) => {
  const res = await fetch(`${ApiConfig.events}${params.id}`);
  const event = await res.json();
  return event

}

export default function EventListSingle ({filterCurrentUser}) {
	//const {id:event_id} = useParams();
  const eventRes  = useLoaderData();
  const event = eventRes.data
  const navigate = useNavigate();

  const user = getUser();
  useEffect(()=>{
    if(filterCurrentUser && !user){
      navigate('/login');
    }
  },[])
  // SEARCH
  const [nameSearch, setNameSearch] = useState("");
  const [dateStartSearch, setDateStartSearch] = useState("");
  const [dateEndSearch, setDateEndSearch] = useState("");
  const [columns, setColumns] = useState([]);

	// compostage
	const [showQrCodeModal, setShowQrCodeModal] = useState(false);

	const openComposteModal = (e) => {
		e.preventDefault();
		setShowQrCodeModal(true);
	}

	const onHide = () => {
		setShowQrCodeModal(false);
	}
  
  // get column
  useEffect(()=>{
    const columns_builder = [
      {
        title: ( <strong>Nom de l'acheteur</strong>),
        render: (_,row) => { 
          return row.client_name
        },
        filterIcon: () => <FontAwesomeIcon icon={faMagnifyingGlass} />,
        filterDropdown: () => <input type="text" value={nameSearch} onChange={e=>setNameSearch(e.target.value)} placeholder='Rechercher par nom' />
      },      
      {
        title: 'Telephone',
        render: (_,row) => row.client_phone,
      },
      {
        title: 'Total',
        render: (_,row) => row.total_price,
      },
      
      {
        title: (<p>Tickets   <Badge bg="success">Composté</Badge></p>),
        render: (_,row) =>{
               return <BilleterieOrder row={row}/>
        },
      },
      {
        title: 'Paiement',
        render: (_,row) => {
          if(row.payment_method == "wave")
            return <img src ="/images/wave.png" style={{width: "50px", height: "auto"}}/>
          if(row.payment_method == "om")
            return <img src ="/images/om.png" style={{width: "50px", height: "auto"}}/>
          if(row.payment_method == "visa")
            return <img src ="/images/visa.svg" style={{width: "50px", height: "auto"}}/>

          return row.payment_method
          },
      },
    ];

    setColumns(columns_builder);
  },[])

// LISTEN SEARCH
  // useEffect(()=>{
  //   let filters = {};
  //   if(nameSearch)
  //     filters['title'] = nameSearch

  //   if(dateStartSearch){
  //     filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
  //     filters['date_time']['date_start'] = dateStartSearch
  //   }

  //   if(dateEndSearch){
  //     filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
  //     filters['date_time']['date_end'] = dateEndSearch
  //   }
  //   console.log('FINALE FILTER')
  //   console.log(filters);
  //   console.log(encodeURI(JSON.stringify(filters)))
  // },[nameSearch, dateStartSearch, dateEndSearch])

 // EVENT
//  const [event, setEvent] = useState({'id':event_id})
//  useEffect(()=>{
// 	 const fetchEvent = async () => {
// 		 const res = await fetch(`${ApiConfig.events}${event_id}`);
// 		 const eventRes = await res.json();
// 		 setEvent(eventRes.data);
// 	 }
// 	 fetchEvent().catch(e=>console.log(e));
//  },[])

  // DATATABLE
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);
  const [ticketMeta, setTicketMeta] = useState({});
  const [billetMeta, setBilletMeta] = useState({});


	const fetchOrders = async page => {
		setLoading(true);
    // console.log("CURRENT PER PAGE", perPage)
    let start = (page - 1) * perPage ;
    let end = page * perPage;

    let filter = {
      'event_id': event.id
    };
    if(filterCurrentUser){
      //filter.customer_id =  user.uid
    } 
    // console.log("fiLTRE")
    // console.log(filter)

		//const response = await fetch(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,{
		const response = await fetch(`${ApiConfig.orders}?_start=${start}&_end=${end}&_child=true&_childRef[]=orderItems&_childRef[]=keper&_parent_field=order&filtre=${encodeURIComponent(JSON.stringify(filter))}`,{
      headers:{
        'Content-Type':'application/json'
      }
    });
    let orders = await response.json();
		setTotalRows(orders.total);
    
    orders = AppConfig.appendCompostToOrders(orders);
    // console.log(orders);
    let tickets = orders.map((order)=>{
      return order.billeterie.tickets
    }).flat()
    
    const billeterie_meta = AppConfig.getBilleterieMeta(tickets);
    const tickets_meta = AppConfig.getTicketsMeta(eventRes.data.ticket_infos);
    // console.log("EVENT", eventRes.data)
    
		setData(orders);
    // console.log("tickets meta",tickets_meta)
    // console.log("billeterie_meta",billeterie_meta);
    setTicketMeta(tickets_meta);
    setBilletMeta(billeterie_meta);
		setLoading(false);
	};

	const handlePageChange = page => {
		fetchOrders(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
    //fetchUsers(page);
	};

  const handleChange = ({ selectedRows }) => {
    console.log(selectedRows);
  };

	useEffect(() => {
		fetchOrders(page); // fetch page 1 of users	
	}, [perPage, page]);

	return (
    <>
					<h1><FontAwesomeIcon icon={faCircleLeft} onClick={() => navigate(-1)}/> {event.title} </h1>
					<Link className="btn btn-primary" to={`/events/${event.id}`}>Voir l'évènement</Link>
					<Button className="btn btn-warning" onClick={openComposteModal}>Composter</Button>
        <div id="event_tickets_card">
          {ticketMeta && ticketMeta[AppConfig.pay_cta] && (
          <Card  title="Payant" className='event_ticket_card'>
            <Table 
              columns={[
                {title:"Evenement", render:(_,row)=>row.title},
                {title: "Quantité", render: (_,row)=>row.quantity},
                {title: "Vendus", render: (_,row)=>row.vendus ? row.vendus : 0},
                {title: "Composté", render: (_,row)=>row.composts ? row.composts : 0},              
              ]}
              dataSource={(ticketMeta[AppConfig.pay_cta].map((ticket)=>{
                    let billet_meta = billetMeta[AppConfig.pay_cta][ticket['title']] || null;
                    if (billet_meta)
                    return {...ticket, billet_meta}
                    return ticket
                  })
              )}
              showHeader={true}
              pagination={false}
            />
          </Card>
          )}
          {ticketMeta && ticketMeta[AppConfig.participate_cta] && (
          <Card  title="Inscription" className='event_ticket_card'>
            <Table 
              columns={[
                {title:"Evenement", render:(_,row)=>row.title},
                {title: "Quantité", render: (_,row)=>row.quantity},
                {title: "Inscrits", render: (_,row)=>row.vendus ? row.vendus : 0},
                {title: "Composté", render: (_,row)=>row.composts ? row.composts : 0},              
              ]}
              dataSource={(ticketMeta[AppConfig.participate_cta].map((ticket)=>{
                    let billet_meta = billetMeta[AppConfig.participate_cta][ticket['title']] || null;
                    if (billet_meta)
                    return {...ticket, billet_meta}
                    return ticket
                  })
                )}
              showHeader={true}
              pagination={false}
            />
          </Card>
          )}
          {ticketMeta && ticketMeta[AppConfig.donate_cta] && (
          <Card  title="Donation" className='event_ticket_card'>
           <Table 
              columns={[
                {title:"Ticket", render:(_,row)=>row.title},
                {title: "Recettes", render: (_,row)=>row.vendus ? row.vendus : 0},
                {title: "Composté", render: (_,row)=>row.composts ? row.composts : 0},              
              ]}
              dataSource={(ticketMeta[AppConfig.donate_cta].map((ticket)=>{
                    let billet_meta = billetMeta[AppConfig.donate_cta][ticket['title']] || null;
                    if (billet_meta)
                    return {...ticket, billet_meta}
                    return ticket
                  })
                )}
              showHeader={true}
              pagination={false}
            />
          </Card>
          )}        
        </div>
        <Table 
          columns={columns}
          dataSource={data}
          childrenColumnName='antdChildren'
          pagination={{
            total: totalRows,
            pageSize: perPage,
            onChange: (page) =>{
              setPage(page)
            }
          }}
          loading={loading}
        />
     
			<QrCodeModal show={showQrCodeModal} onHide={onHide}/>
    </>
	);
};
