'use client';

import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import styles from './list.module.css';
import 'app/admin.css'
import moment from 'moment';

import ApiConfig from 'app/ApiConfig';
import AppConfig from 'app/AppConfig';

import { useRecoilState } from 'recoil';
import { createEventForm , createEventSteps} from 'app/states/event_state';

import QrCodeModal from 'app/components/qrcode-modal/QrCodeModal';
import Billeterie from 'app/components/events/Billeterie';

import EventGenerateModal from 'app/events/generate/EventGenerateModal';
import DeleteEntityModal from 'app/components/DeleteEntityModal';

import { getUser } from 'app/user_state';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Grid } from 'antd';
import { Dropdown, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
const { useBreakpoint } = Grid;

//moment().locale('fr');

export default function EventList ({filterCurrentUser}) {
  
  const navigate = useNavigate();
  const screens = useBreakpoint()

  // User List
  const user = getUser();

  useEffect(()=>{
    if(filterCurrentUser && !user){
      navigate('/login');
    }
  },[])


  // SEARCH
  const [nameSearch, setNameSearch] = useState("");
  const [dateStartSearch, setDateStartSearch] = useState("");
  const [dateEndSearch, setDateEndSearch] = useState("");
  const [columns, setColumns] = useState([]);
  const [tickets, setTickets] = useState({});

  useEffect(()=>{
    let filters = {};
    if(nameSearch)
      filters['title'] = nameSearch

    if(dateStartSearch){
      filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
      filters['date_time']['date_start'] = dateStartSearch
    }

    if(dateEndSearch){
      filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
      filters['date_time']['date_end'] = dateEndSearch
    }
    // console.log('FINALE FILTER')
    // console.log(filters);
    // console.log(encodeURI(JSON.stringify(filters)))
  },[nameSearch, dateStartSearch, dateEndSearch])


  // compostage
	const [showQrCodeModal, setShowQrCodeModal] = useState(false);
	const openComposteModal = (e) => {
		e.preventDefault();
		setShowQrCodeModal(true);
	}
	const onHide = () => {
		setShowQrCodeModal(false);
	}

  // update
  const [eventForm, setEventForm] = useRecoilState(createEventForm);
  const [currentStep, setCurrentStep] = useRecoilState(createEventSteps);

  const [showUpdateEventModal, setShowUpdateEventModal] = useState(false);
	const openUpdateEventModalModal = (e, event) => {
		e.preventDefault();
    console.log(event);
    setCurrentStep(0)
    setEventForm(event);
		setShowUpdateEventModal(true);
	}
	const onHideUpdateEventModal = () => {
		setShowUpdateEventModal(false);
	}
  // delete
  //
  const [showDeleteEventModal, setShowDeleteEventModal] = useState(false)
  const [toDeleteEvent, setToDeleteEvent] = useState({});
  const openDeleteEventModalModal = (e, event) => {
    e.preventDefault();
    setToDeleteEvent(event);
    setShowDeleteEventModal(true);
  }
  const onHideDeleteEventModal = (e) => {
    setShowDeleteEventModal(false)
  }

  // get column
  useEffect(()=>{
    const columns_builder = [
      {
        dataIndex: 'title',        
        title: (<strong>Evenement</strong>),
        render: (title,row) => {         
          return <strong className='text-link' onClick={(e)=>{
            console.log('clicked')
            setExpandedRowKeys((old)=>{
              let in_array = old.find((elem)=>elem == row.id);
            if(in_array){
              console.log('in array')
              return []
            } else{
              console.log('not in array')
              return [row.id]
            }
            })
            
            
          }}>{row.title}</strong>
        },
        filterIcon: () => <FontAwesomeIcon icon={faMagnifyingGlass} />,
        filterDropdown: () => <input type="text" value={nameSearch} onChange={e=>setNameSearch(e.target.value)} placeholder='Rechercher par nom' />
      },
      { 
        
        title: (<strong>Date</strong>),
        render: (_,row) => {
          return moment(row.date_time.date_start).format('D MMMM YYYY'); // March 25th 2024, 6:58:12 pm
        },
        filterIcon: () => <FontAwesomeIcon icon={faMagnifyingGlass} />,
        filterDropdown: () => (
          <div className={styles.form_inline_group}>
              <label>Date début<input type="date" value={dateStartSearch} onChange={e=>setDateStartSearch(e.target.value)}/></label><br/>
              <label>Date fin<input type="date" value={dateEndSearch} onChange={e=>setDateEndSearch(e.target.value)} /></label>
            </div>
        )      
      },
      {
        dataIndex: 'summary',
        title: <strong>Resumé</strong>,
        render: (_,row) => (<p dangerouslySetInnerHTML={{ __html: row.summary }}></p>),
        // sortable: true,
        // wrap: true
        width: '30vw',
        responsive: ['lg'],
      },
      // {
      //   name: 'Types de tickets',
      //   selector: (row) =>{
      //     return row.ticket_infos.map((ticket)=>{
      //       let text_quantity = ticket.type == 'donation' ? "" : ` - (${ticket.quantity_left}/${ticket.quantity})`
      //       return (<>
      //       <span className={`badge badge-${ticket.type}`}>{ticket.title} {text_quantity}</span><br/>
      //       </>)
      //     })        
      //   },
      //   sortable: true,
      // },
      // {
      //   title: (
      //     <tr>
      //           <th>Billeterie</th>
      //           <th><Badge bg="secondary">Disponible</Badge></th>
      //           <th><Badge bg="warning">Vendu</Badge></th>
      //           <th><Badge bg="success">Composté</Badge></th>
      //         </tr>),
      //   render: (_,row) =>{
      //         return (<Billeterie row={row} />)             
      //   },
      //   responsive: ['md'],
      // },
      {
        title: 'Action',
        render: (_,row) =>(
          <>
          <Dropdown>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        Paramètres
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={openComposteModal}>Composter</Dropdown.Item>
        <Dropdown.Item onClick={e=>openUpdateEventModalModal(e, row)}>Modifier</Dropdown.Item>
        <Dropdown.Item onClick={e=>openDeleteEventModalModal(e, row)}>Supprimer</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
          </>
        ),
        // sortable: true,
        // width: 'fit-content'
      },
      
    ];

    setColumns(columns_builder);
    //fetchEvents(1);
  },[])

  // DATATABLE
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);

	const fetchEvents = async page => {
		setLoading(true);
    //console.log("CURRENT PER PAGE", perPage)
    let start = (page - 1) * perPage ;
    let end = page * perPage;

    let user_filter = {};
    if(filterCurrentUser){
      user_filter.owner_id =  user.uid
    } 

		//const response = await fetch(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,{
		const response = await fetch(`${ApiConfig.events}?_start=${start}&_end=${end}&_child=true&_childRef[]=tickets&_childRef[]=keper&_parent_field=event&filtre=${ encodeURIComponent(JSON.stringify(user_filter)) }`,{
      
      headers:{
        'Content-Type':'application/json'
      }
    });
    let events = await response.json();
		setTotalRows(events.total);
    // append billeterie
    events = AppConfig.appendCompostToEvents(events);
    console.log("EVENTS");
    console.log(events);
		setData(events);
		setLoading(false);
	};

	const handlePageChange = page => {
		fetchEvents(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
    //fetchUsers(page);
	};

  const handleChange = ({ selectedRows }) => {
    console.log(selectedRows);
  };

	useEffect(() => {
		fetchEvents(page); // fetch page 1 of users
	}, [perPage, page, filterCurrentUser]);

  // TABLE ROWS
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const onTableRowExpand = (expanded, record) => {
    const keys = [];
    if(expanded){
        keys.push(record.id); // I have set my record.id as row key. Check the documentation for more details.
    }

    setExpandedRowKeys(keys);
}

	return (
    <>
        <Table
          columns={columns}
          childrenColumnName="antdChildren"
         // rowKey={(record) => record.login.uuid}
          dataSource={data}
          expandedRowKeys={expandedRowKeys}
          onExpand={onTableRowExpand}
          rowKey={(record)=>record.id}
          loading={loading}          
          pagination={{
            pageSize: perPage,
            total: totalRows,
            onChange: (page) =>{
              setPage(page)
            }
          }}

          expandable={{
            rowExpandable: (record) => (true),
            expandedRowRender: (record) => {
              let url = `/admin/events/list/${record.id}`
              return (<>
            
                <Link to={url}>Voir l'évènement</Link>  
                <br/>         
                <Billeterie row={record} />
                </> 
              )           
          },
          }}
        />
      <QrCodeModal show={showQrCodeModal} onHide={onHide}/>
      <EventGenerateModal show={showUpdateEventModal} onHide={onHideUpdateEventModal} />
      <DeleteEntityModal show={showDeleteEventModal} onHide={onHideDeleteEventModal} entity={toDeleteEvent}/>
    </>
	);
};
