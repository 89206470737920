import styles from './organisateur-home-card.module.css';

export default function OrganiseurHomeCard({orga}) {
  return (
    <div className={styles.organisateur_card}>
      <img
        loading="lazy"
        src={orga.image ? orga.image : "https://firebasestorage.googleapis.com/v0/b/shs-backend.appspot.com/o/profiies%2FProfile-PNG-File.png?alt=media&token=75f5857b-ec72-4683-979e-de34cad42c32"}
        className={styles.organisateur_img}
      />
      <br />
      <p className={styles.organisateur_name}>{orga.name}</p>
      <p className={styles.organisateur_followers}>0 abonnée(s)</p>
      <button className="btn eventkati_btn">Suivre</button>
    </div>
  )
}
