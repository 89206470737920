import ApiConfig from "app/ApiConfig"
import { default_event_filter } from "app/states/event_state";
import set from 'lodash/fp/set'
import omit from 'lodash/fp/omit'
import _ from 'lodash'

export const listEvents =  async (filter = {}, page, perPage) => {
  let url = ApiConfig.events;
  // pagination
  let start = (page - 1) * perPage ;
  let end = page * perPage;
  url += `?_start=${start}&_end=${end}`

  //url += `?_start=${0}&_end=${perPage}`
      
  // filtres
  // let search_filter = Object.assign({},default_event_filter['search']);
  let search_filter = {...default_event_filter['search']}; 
  if(Object.keys(filter).length > 0){  
      if(_.isEmpty(filter['location_data'])) {
        if(filter['location_data']['locationMeta'] != ""){
          search_filter = set('location_data.locationMeta', filter['location_data']['locationMeta'], search_filter)
        } else {
          search_filter = omit('location_data.locationMeta', search_filter)
        }
      } else {
        search_filter = omit('location_data', search_filter)
      }
      if(_.isEmpty(filter['date_time'])) {
        if(filter['date_time']['date_start'] != ""){
          search_filter = set('date_time.date_start', filter['date_time']['date_start'], search_filter)
        } else {
          search_filter = omit('date_time.date_start',search_filter);
        }        
      } else {
        search_filter = omit('date_time', search_filter)
      }
      if(filter['category_id']) {
        search_filter = set('category_id', filter['category_id'], search_filter)
      } else {
        search_filter = omit('category_id', search_filter)
      }
      let filter_url = encodeURIComponent(JSON.stringify(search_filter));
      url = `${url}&filtre=${filter_url}`   
  }
  // request
  const res = await fetch(url);
  const eventsRes = await res.json();
  return eventsRes;
}

