import React, {useState, useEffect} from 'react'
import styles from './messag.module.css'
import { postMessage } from 'app/ressources/messages'
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import SuccessCheck from 'app/components/success-check/SuccessCheck';
export default function MessageForm({planner_id, sender_id, onHide}) {
   // STATE
   const default_message = {
    lname: "",
    fname: "",
    phone: "",
    subject: "event",
    message: "",
    
    planner_id,
    sender_id
  }

  const [message, setMessage] = useState(default_message)
  const [messageSent, setMessageSent ] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formRes, setFormRes ] = useState(null);

  //
  const handleInputChange = (e) => {
    setMessage((old)=>({...old, [e.target.id]:e.target.value}))
  }

  const handleChange = (e) => {
    setMessage((old)=>({...old, [e.target.id]:e.target.value}))
  }


  
  const handleSubmit = async (e)=>{
    e.preventDefault();
    //
    setIsLoading(true);
    console.log(message)
    const response = await postMessage(message);
    console.log("RESPONSE")
    console.log(response)
    setFormRes(response);
    if(response.data){
       setMessageSent(true)
       setIsLoading(false);
    } 
  }

  const closeForm = (e) => {
    setMessageSent(false)
    setMessage(default_message);
    setFormRes(null);
    onHide()
  }

  return (
    <>
    {!messageSent ? (
    <section>
      <div className={styles.form_group}>
        <label htmlFor="">Nom Prenom</label>
        <input type="text" name="fname" id="fname" placeholder="Nom" value={message.fname} onChange={(e)=> handleInputChange(e)}/>
      </div>

      <div className={styles.form_group}>
        <label htmlFor="">Sujet</label>
        <Form.Select aria-label="Default select example" name="subject" id="subject" onChange={(e)=> handleChange(e)}>
          <option value="event" id="subject">A propos de l'évènement</option>
          <option value="tickets" id="subject">A propos de mes tickets</option>
       </Form.Select>
      </div>

      <div className={styles.form_group}>
        <label htmlFor="">Téléphone</label>
        <input type="text" name="phone" id="phone" placeholder="Telephone" value={message.phone} onChange={(e)=> handleInputChange(e)}/>
      </div>

      <div className={styles.form_group}>
        <label htmlFor="">Message</label>
        <Form.Control as="textarea" rows={3} id="message" value={message.message} onChange={(e)=> handleInputChange(e)} />
        {/* <textarea name="titre" id="message" placeholder="Message" value={message.message} onChange={(e)=> handleInputChange(e)}/> */}
      </div>
      <br/>
      {(formRes && formRes['errors']) && JSON.stringify(formRes)}
      <div className={`${styles.form_inline_group} form_pull_right_cta`}>
        <button onClick={onHide} className='btn btn-secondary'>Fermer</button>
        <button onClick={handleSubmit} className='btn_cta'>{isLoading ? <Spinner animation="border" /> : "Envoyer" }</button>
      </div>

    </section> 
    ) : (
      <div className="form_success">
        <SuccessCheck/>
        <button onClick={closeForm} className='btn btn-secondary'>Fermer</button>
      </div>
    )}
  </>
  )
}
