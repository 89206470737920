import styles from "./create_transportation_modal.module.css";
import {useState, useEffect} from 'react';
import ApiConfig from "app/ApiConfig";
import { createEventForm } from "app/states/event_state";
import { useRecoilState } from "recoil";
import { set } from "lodash/fp";

export default function CreateTransportationModalContent({currentTransport, onClose}) {

  // STATE
  const default_transport = {
    name: "",
    description: "",
    maxCapacity: 0,
    images:[],
    videoUrls:[]
  } 
  const [transport, setTransport] = useState(default_transport)
  const [videoUrls, setVideoUrls] = useState([]);
  const [formErrors, setFormErrors] = useState(null);
  //
  const [eventForm, setEventForm] = useRecoilState(createEventForm)
  useEffect(()=>{
    if(currentTransport){
      setTransport(currentTransport)
      setVideoUrls(transport.videoUrls || [])
    } else {
      setTransport(default_transport)
    }
  },[])
  // const [name, setName] = useState("");
  // const [description, setDescription] = useState("");
  // const [maxCapacity, setMaxCapacity] = useState(0)
  // const [images, setImages] = useState([])
  
  const handleFileChange = (e) => {
    e.preventDefault();
    let files = e.target.files;
    // for(let i = 0; i <files.length; i++){
    //   console.log(files[i]);
    // }
    if(files.length){
      // setImages(files)
      setTransport((old)=> ({...old, images: files}))
    }
  }
  const addVideoField = (e) =>{
    e.preventDefault();
    setVideoUrls((oldVal)=>{
      return [...oldVal, ""];
    })
  }
  const setVideoUrl = (e, idx)=>{
    setVideoUrls((oldVal)=>{
      oldVal[idx] = e.target.value;
      let newval = [...oldVal];
      setTransport((old)=>({...old, 'videoUrls': newval}))
      return newval
    })
  }

  //
  const handleInputChange = (e) => {
    
    setTransport((old)=>({...old, [e.target.id]:e.target.value}))
  }
  
  const handleSubmitTransportation = async (e)=>{
    e.preventDefault();
    //
    let formData = new FormData();
    formData.append('name', transport.name)
    formData.append('description', transport.description)
    formData.append('maximum_capacity', transport.maxCapacity)
    if(transport.images) {
      for(let i = 0; i <transport.images.length; i++){
        //formData.append('image_urls', images[i])
        formData.append('image_urls', transport.images[i])
      }
    }
    //formData.append('file', images[0])
    formData.append('videos_urls', videoUrls)
    //
    let transReq = null
    if(!transport.id){
       transReq = await fetch(ApiConfig.transportations, {
          method: "POST",
          body: formData
        })
      } else {
      transReq = await fetch(`${ApiConfig.transportations}${transport.id}`, {
         method: "PUT",
         body: formData
       })
    }
    const transRes = await transReq.json();
    const response = transRes.data;
    if(response.error) {
      setFormErrors(response.error)
    } else {
      console.log(response);
      setFormErrors(set('transportation_data', {transport_id: response.id, transport_name: response.name}))
      onClose();
    }
  }

  return (
    <>
      <div className={``}>
      <section>
        <div className={styles.form_group}>
          <label htmlFor="">Name</label>
          <input type="text" name="titre" id="name" placeholder="Titre" value={transport.name} onChange={(e)=> handleInputChange(e)}/>
        </div>

        <div className={styles.form_group}>
          <label htmlFor="">Description</label>
          <textarea name="" id="description" cols="30" rows="4"onChange={(e)=> handleInputChange(e)} value={transport.description}></textarea>
        </div>

        <div className={styles.form_group}>
          <label htmlFor="">Maximum capacity</label>
          <input type="number" name="" id="maxCapacity" value={transport.maxCapacity}
          onChange={(e)=> handleInputChange(e)}
          placeholder="Maximum capacity" />
        </div>
        <div className={styles.form_group}>
          <label htmlFor="file" className="sr-only">
            Choisir images
          </label>
          <input id="file" type="file" multiple onChange={handleFileChange} />
        </div>
        <div className={styles.form_group}>
          {videoUrls.map((v,index) => {
            return (
              <div key={index}>
                <label htmlFor="video_urls" className="sr-only">
                  Lien vidéo
                </label>
                <input id="video_urls" type="text" onChange={(e)=>setVideoUrl(e,index)} value={videoUrls[index]} />
              </div>
            )
          })}
          <button type="button" onClick={addVideoField}>Ajouter une vidéo</button>
          
        </div>
        {formErrors ? <p className="text-error">{formErrors}</p> : "" }
        <div className={`${styles.form_inline_group} form_pull_right_cta`}>
          <button onClick={handleSubmitTransportation} className={styles.submit_event}>Sauvegarder et continuer</button>
        </div>
      </section> 
      </div>
    </>
  )
}