"use client";

import AppConfig from "app/AppConfig";

import styles from "./create_event.module.css";

import { useState } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { createEventForm } from 'app/states/event_state';
import { Modal, Table } from "react-bootstrap";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan, faFilePen } from "@fortawesome/free-solid-svg-icons";


export default function CreateEvent3({prev, next, display}) {
  //setup
  const [eventForm, setEventForm ] = useRecoilState(createEventForm);
  const defaultBillet = {
    type: "paid",
    title: "",
    quantity: "",
    price: "",
    devise: "",
    sales_date: {
      date_start: "",
      time_start: "",
      date_end: "",
      time_end: ""
    }
  };
  const [billetForm, setBilletForm] = useState(defaultBillet)
  //
  // new billet
  const [showBilletForm, setShowBilletForm] = useState(false);
  const handleNewBillet = (e)=>{
    e.preventDefault();
    setBilletForm((old)=> {
      return {...old, title: "", quantity: "", price: ""}
    })
    setShowBilletForm(true);
  }
  // update billet
  const handleUpdateBillet = (billet) => {
    setBilletForm({...billet});
    setShowBilletForm(true);
  }
  // delete billet
  const handleDeleteBillet = (billet) => {
    setEventForm((old)=>{
      let tickets = old.ticket_infos;
      tickets = tickets.filter((elem)=>elem.title != billet.title);
      return {...old, ticket_infos: tickets}
    })
  }

  // manage creation
  const handleInputChange = (e, field, subfield = null, value = null) => {
    let val = e?.target?.value || value
    setBilletForm((old,curr)=> {
      if(subfield) {
        let obj = {
          [field]:{
            ...old[field],
            [subfield]: val
          }
        };
        return {...old, ...obj}
      } else {      
        return {...old , [field]: val}
      }
    })
  }
  const handleSelectBilletType = (e) => {
    e.preventDefault();
    let target = e.target.getAttribute('id');
    setBilletForm((old)=>{
      return {...old, "type": target}}
    )
  }
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  const handleBilletCreate = (e)=> {
    e.preventDefault();
    console.log(billetForm);    
    setEventForm(old =>{
      let tickets = [...old.ticket_infos];
      tickets = tickets.filter((elem)=>elem.title != billetForm.title)
      tickets.push(billetForm);
      return {...old, ticket_infos:tickets}
    })
    setShowBilletForm(false);
  }
  return (
    <>
      <div className={`${display != 'update_modal' ? styles.form_section : ""} admin_page_billet_content`}>
        <div id="add_billet_panel">
          {eventForm.ticket_infos.length == 0 && (
            <>
              <img src="/icons/billet_icon.png" alt="" />
              <p>Créons la tarification</p>
              <br />         
            </>
          )}
          {eventForm.ticket_infos.length > 0 && (  
            <>
          <div id="billets_table">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Ticket</th>
                <th>Date</th>
                <th>Quantité</th>
                <th>Prix</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {eventForm.ticket_infos.map((billet)=>{              
                return (               
                        <tr>
                          <td><strong>{billet.title}</strong>                          
                          </td>
                          <td>{billet.sales_date.date_start}</td>
                          <td>{billet.quantity}</td>
                          <td>{billet.price}</td>   
                          <td>
                            <button onClick={(e)=>handleUpdateBillet(billet)} className="btn btn-secondary">
                            <FontAwesomeIcon icon={faFilePen} /></button> 
                            <button onClick={(e)=>handleDeleteBillet(billet)} className="btn btn-danger">
                            <FontAwesomeIcon icon={faTrashCan} /></button>  
                          </td>
                        </tr>                    
                )
              })}   
            </tbody>
          </Table>      
          </div>          
          </>
          )}
          <div className="inline_text">
            {/* <button className={styles.normal_button}>Créer une rubrique</button> */}
            {eventForm.ticket_infos.length>0 && <button className={styles.submit_event_center} onClick={next}>Suivant</button>}
            <button onClick={handleNewBillet} className={styles.cta_button_blue}>Ajouter un prix</button>
          </div>        
        </div>
        <div id="add_billet_block">

      </div>
      </div>
      <Modal show={showBilletForm} onHide={(e)=>setShowBilletForm(false)}>
        <Modal.Header>
          Ajouter un billet
        </Modal.Header>
        <Modal.Body>
        <section >
            <strong>Ajouter un billet</strong>
            <div className={styles.form_inline_group}>        
              <button 
                className={`${billetForm.type == AppConfig.pay_cta ? styles.btn_active : ''} ${styles.btn_default}`}  
                onClick={e=>handleSelectBilletType(e)}
                id={AppConfig.pay_cta}>Payant</button>
              <button 
                className={`${billetForm.type == AppConfig.participate_cta ? styles.btn_active : ''} ${styles.btn_default}`}  
                onClick={e=>handleSelectBilletType(e)}
                id={AppConfig.participate_cta}>Gratuit</button>
              <button 
                className={`${billetForm.type == AppConfig.donate_cta ? styles.btn_active : ''} ${styles.btn_default}`} 
                onClick={e=>handleSelectBilletType(e)}
                id={AppConfig.donate_cta}>Dons</button>
            </div>
            <div className={styles.form_group}>
              <label htmlFor="">Nom</label>
              <input type="text" value={billetForm.title} onChange={e=>handleInputChange(e, "title")} />
            </div>        
            {(billetForm && (billetForm.type == AppConfig.pay_cta || billetForm.type == AppConfig.participate_cta)) && (    
              <div className={styles.form_group}>
                <label htmlFor="">Quantité disponible</label>
                <input type="number" value={billetForm.quantity} onChange={e=>handleInputChange(e, "quantity")} />
              </div>  
            )}
            {(billetForm && (billetForm.type == AppConfig.pay_cta)) && ( 
              <div className={styles.form_group}>
                <label htmlFor="">Prix</label>
                <input type="text" value={billetForm.price} onChange={e=>handleInputChange(e, "price")} />
              </div>
            )}            
            
            <div className={`${styles.form_inline_group} ${styles.timing_form}`}>
              <div className={`${styles.form_group}`}>
                <label htmlFor="">début des ventes</label>
                {/* <input type="date" name="" id="" 
                value={billetForm.sales_date.date_start}
                onChange={(e)=> handleInputChange(e, "sales_date","date_start")}/> */}
                
                <DatePicker 
                disabledDate={disabledDate}
                value={billetForm.sales_date.date_start ? dayjs(billetForm.sales_date.date_start, "DD-MM-YYYY") : ""}
                format={"DD-MM-YYYY"} 
                onChange={(e,date)=> handleInputChange(e, "sales_date","date_start", date)}     
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}     
                /> 
              </div>
              <div className={`${styles.form_group}`}>
                <label htmlFor="">Heure de début</label>
                 {/*<input type="time" name="" id="" 
                value={billetForm.sales_date.time_start}
                onChange={(e)=> handleInputChange(e, "sales_date","time_start")}/> */}

                <TimePicker format="HH:mm" minuteStep={15} needConfirm={false}
                value={billetForm.sales_date.time_start ? dayjs(billetForm.sales_date.time_start, "HH:mm") : ""}
                onChange={(e,val)=> handleInputChange(e, "sales_date","time_start",val)} 
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                />
              </div>
            </div>
            <div className={`${styles.form_inline_group} ${styles.timing_form}`}>
              <div className={`${styles.form_group}`}>
                <label htmlFor="">fin des ventes</label>
                {/* <input type="date" name="" id=""
                value={billetForm.sales_date.date_end}
                onChange={(e)=> handleInputChange(e, "sales_date","date_end")} /> */}
                <DatePicker 
                disabledDate={disabledDate}
                value={billetForm.sales_date.date_end ? dayjs(billetForm.sales_date.date_end, "DD-MM-YYYY") : ""}
                format={"DD-MM-YYYY"} 
                onChange={(e,date)=> handleInputChange(e, "sales_date","date_end", date)}     
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}     
                /> 
              </div>
              <div className={`${styles.form_group}`}>
                <label htmlFor="">Heure de fin</label>
                {/* <input type="time" name="" id="" 
                value={billetForm.sales_date.time_end}
                onChange={(e)=> handleInputChange(e, "sales_date","time_end")} /> */}

                 <TimePicker format="HH:mm" minuteStep={15} needConfirm={false}
                value={billetForm.sales_date.time_end ? dayjs(billetForm.sales_date.time_end, "HH:mm") : ""}
                onChange={(e,val)=> handleInputChange(e, "sales_date","time_end",val)} 
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                />
              </div>
            </div>
            <div className={`${styles.form_inline_group} form_pull_right_cta`}>
              <button onClick={(e)=>setShowBilletForm(false)} className="btn btn-secondary">Annuler</button>
              <button onClick={handleBilletCreate} className="btn eventkati_btn">Sauvegarder</button>
            </div>
          </section>
        </Modal.Body>
      </Modal>

    </>
  )
}