'use client';
import {useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from 'app/firebase'

import styles from './login.module.css'
import Footer from 'app/components/Footer';
import { Modal, Button } from 'react-bootstrap';
import ApiConfig from 'app/ApiConfig';

import { setUser, getUser, logout } from 'app/user_state';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Navigate, useNavigate } from 'react-router-dom';
import { Alert, Spin, Input } from 'antd';

export default function Login() {
  // getAUTH
  auth.useDeviceLanguage();
  // PHONE LOGIN
  const [ phone, setPhone ] = useState("+221")
  const [ acceptOTP , setAcceptOTP] = useState(false);
  const [ otp , setOTP] = useState("");
  const [ nomPrenom , setNomPrenom] = useState("");
  const [ showPhoneModal, setShowPhoneModal ] = useState(false);
  const [ isNewUser, setIsNewUser ] = useState(false);
  const [ newUser, setNewUser ] = useState(null);
  const [errorSMS, setErrorSMS ] = useState("");
  const [errorGoogle, setErrorGoogle ] = useState("");
  const [loaders, setLoaders ] = useState({});
  
  const [ loginTarget , setLoginTarget] = useState("");
  const navigate = useNavigate();

  const handleClose = () => {
    setShowPhoneModal(false);
  }
  useEffect(()=>{
    // erase old session
    logout();
    // set recaptcha
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'phone-signup', {
      'size': 'invisible'});
  },[])
  // PHONE NUMBER
  const handlePhoneChange = (e) =>{
    e.preventDefault();
    setPhone(e.target.value);
  }
  const handlePhoneLogin = (e, target) => {

    setLoginTarget(target);
    setLoaders({'phone':true})

    e.preventDefault()
    signInWithPhoneNumber(auth, phone, window.recaptchaVerifier)
    .then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      window.confirmationResult = confirmationResult;
      setShowPhoneModal(true)
      // ...
      setLoaders({})
    }).catch((error) => {
      // Error; SMS not sent
      // ...
      console.log("ERROR SMS")
      setErrorSMS(error.message)
      //console.log(error)
      setLoaders({})
    });
  }

  // OTP CODE
  const handleOTPChange = (e) =>{
    e.preventDefault();
    setOTP(e.target.value);
  }
  
  const handleConfirmOTP = async (e) => {
    e.preventDefault();
    window.confirmationResult.confirm(otp).then(async (result) => {
      // User signed in successfully.
      const user = result.user;
      // console.log("USER FROM PHONE")
      // console.log("NOM PRENOM", nomPrenom)
      // console.log(user)

      const checkUser = await fetch(`${ApiConfig.users}?filtre=${encodeURIComponent(JSON.stringify({uid: user.uid}))}`);
      const resUser = await checkUser.json();

      if(resUser.data.length == 0) {
        // set new user
        setNewUser(user)
        setIsNewUser(true)

      } else { // nosignup
        setUser(resUser.data[0]);
        navigate('/')
      }
      
      setLoaders({})
    }).catch((error) => {
      // User couldn't sign in (bad verification code?)
      // ...
      console.log("ERROR OTP")
      console.log(error);
      setLoaders({})
    });

  }
  const handleConfirmOTPNewUSer = async (e) => {
    let _current_user = {
      uid: newUser.uid,
      name: nomPrenom,
      phone: phone
    }

    const createUserReq = await fetch(ApiConfig.users, {
      method: "POST",
      body: JSON.stringify(_current_user),
      headers: {
        'Content-Type':'application/json'
      }
    });
    const userResponse = await createUserReq.json();
    const _user = userResponse.data
    // save in localStorage
    setUser(_user)
    navigate('/')
  }

  // GOOGLE
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  const handleGoogleSignin = (e) => {
    e.preventDefault();
    setLoaders({'google': true})
    signInWithPopup(auth, provider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      

      let _current_user = {
        uid: user.uid,
        name: user.displayName,
        email: user.email
      }

      // create user if do not exist
      const createUserReq = await fetch(ApiConfig.users, {
        method: "POST",
        body: JSON.stringify(_current_user),
        headers: {
          'Content-Type':'application/json'
        }
      });
      const userResponse = await createUserReq.json();

      setUser(_current_user);
      navigate('/')
      setLoaders({})
      // if exist already
      if(userResponse.error) {
        // get user from db and refresh app state

        // set user for local state
        setUser(_current_user);
        setLoaders({})
        navigate('/')
      }
      // save in localStorage
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("ERROR GOOGLE")
      // console.log(error);
      setLoaders({})
      setErrorGoogle(error.message)
      // ...
    });
  }


  return (
    <>
    <div className={`${styles.login_content}`}>   
      <div>
        <div className={`${styles.login_form}`}>
        <img src="/kati360.jpg" alt="" className={styles.login_logo} />
          <h1>Connexion</h1>
          <div className={`${styles.form_group}`}>
            <label htmlFor="phone_number">Numéro de téléphone</label>
            {/* <input type="text" name="" id="phone_number" value={phone} onChange={handlePhoneChange}  /> */}
            <Input size="large" value={phone} onChange={handlePhoneChange}  prefix={loaders['phone'] && <Spin size="small" />} />
          </div>
          {errorSMS && <Alert
            message={errorSMS}
           // description="This is an error message about copywriting."
            type="error"
            showIcon
          />}
          {!acceptOTP && (
            <div className={`${styles.form_inline_group} `}>
              <button onClick={e=>handlePhoneLogin(e,"signup")} id="phone-signup" className={`${styles.cta_block} `}>
                {loaders['signup'] && <Spin size="small" />}
                S'inscrire
              </button>
              <button onClick={e=>handlePhoneLogin(e,'connect')} id="phone-signup" className={`${styles.cta_second_block} `}>
                {loaders['connect'] && <Spin size="small" />}
                Se connecter
              </button>
            </div>
          )}
          {acceptOTP && (
            <div>
              <div className={`${styles.form_group}`}>
                <label htmlFor="phone_otp">Veuillez saisir votre code de confirmation reçu par SMS</label>
                <input type="text" name="" id="phone_otp" value={otp} onChange={handleOTPChange} />
              </div>
              <div className={`${styles.form_group}`}>
                <button onClick={handleConfirmOTP} className="cta_block">Confirmer le code</button>
              </div>
            </div>
          )}
          <hr />
          <p className={`${styles.continuer_avec}`}>Ou continuer avec</p>
          {errorGoogle && <Alert
            message={errorGoogle}
           // description="This is an error message about copywriting."
            type="error"
            showIcon
          />}
          <button onClick={handleGoogleSignin} className={styles.providers_btn}> <img src="/icons/google.png" alt="" /> {loaders['google'] && <Spin size="small" />}</button>
          <p className={styles.small_text}>
          En cliquant sur Créer un compte ou Se connecter, vous acceptez les conditions d'utilisation, la politique de confidentialité et la politique en matière de cookies
          </p>
        </div> 
      </div> 
      <div className={`${styles.login_img}`}>
        <img src="/images/login_page.jpg" alt="" />
      </div>
    </div>
    <Footer />
    <Modal show={showPhoneModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Connexion par mobile</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loginTarget == 'signup' && (
            <div className={styles.form_group}>
              <label htmlFor="">Nom / Prénom</label>
              <input type="text" name="" id="" placeholder="Nom / Prenom" value={nomPrenom} onChange={(e)=> setNomPrenom(e.target.value)}/>
            </div>
          )}
            <div className={`${styles.form_group}`}>
              <label htmlFor="phone_otp">Veuillez saisir votre code de confirmation reçu par SMS</label>
              <input type="text" name="" id="phone_otp" value={otp} onChange={handleOTPChange} />
            </div>
            {isNewUser && (
              <div className={styles.form_group}>
                <label htmlFor="">Nom / Prénom</label>
                <input type="text" name="" id="" placeholder="Nom / Prenom" value={nomPrenom} onChange={(e)=> setNomPrenom(e.target.value)}/>
              </div>
            )}
            <br></br>
            <div className={`${styles.form_group}`}>
             { isNewUser && <button onClick={handleConfirmOTPNewUSer} className="cta_block">S'inscrire</button> }
             { !isNewUser && <button onClick={handleConfirmOTP} className="cta_block">Confirmer le code</button> }
            </div>
        </Modal.Body>
       
      </Modal>
    </>
  )
}
