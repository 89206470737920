import React from 'react'
import { Carousel } from "react-bootstrap";
import { eventFilterState } from 'app/states/event_state';
import { useRecoilValue } from 'recoil';
export default function HomeCarousel() {

  const eventFilter = useRecoilValue(eventFilterState);
  return (
    <>
    {eventFilter.country.name == "Senegal" && (
      <Carousel fade controls={false} interval={3000} indicators={false} className="carousel">
          <Carousel.Item>  
            <div className="slide_item">
              <div className="overlay" style={{'backgroundImage':`url(/banners/iles_saloum.jpg)`}}></div>
              <h1>Iles du Saloum</h1>             
            </div> 
          </Carousel.Item>       
          <Carousel.Item>       
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/les_cercles_megalithiques.jpg)`}}></div>
            <h1>Les cercles Megalithiques</h1>             
          </div> 
          </Carousel.Item>   
          <Carousel.Item>   
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/lac_rose.jpg)`}}></div>
            <h1>Lac Rose</h1>             
          </div> 
          </Carousel.Item>   
          <Carousel.Item>   
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/goree.jpg)`}}></div>
            <h1>Gorée</h1>             
          </div> 
          </Carousel.Item>   
          <Carousel.Item>   
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/cap_skiring.jpg)`}}></div>
            <h1>Cap Skiring</h1>             
          </div> 
          </Carousel.Item>   
          <Carousel.Item>   
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/saint_louis.jpg)`}}></div>
            <h1>Saint-Louis</h1>             
          </div> 
          </Carousel.Item>   
          <Carousel.Item>   
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/parc_national_des_oiseaux_de_djoudj.jpg)`}}></div>
            <h1>Parc National des oiseaux de Djoudj</h1>             
          </div> 
          </Carousel.Item>   
          <Carousel.Item>   
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/reserve_de_bandia.jpg)`}}></div>
            <h1>Reserve de Bandia</h1>             
          </div> 
          </Carousel.Item>   
          <Carousel.Item>   
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/desert_de_lompoul.jpg)`}}></div>
            <h1>Désert de Lompoul</h1>             
          </div> 
          </Carousel.Item>   
        </Carousel>   
      )}
      {eventFilter.country.name == "Rwanda" && (<>
        <Carousel fade controls={false} interval={3000} indicators={false} className="carousel">
          <Carousel.Item>  
            <div className="slide_item">
              <div className="overlay" style={{'backgroundImage':`url(/banners/rwanda/Rwanda.jpeg)`}}></div>
              <h1>Rwanda</h1>             
            </div> 
          </Carousel.Item>       
          <Carousel.Item>       
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/rwanda/kigali.jpg)`}}></div>
            <h1>Kigali</h1>             
          </div> 
          </Carousel.Item>             
        </Carousel>
      </>)}
      {eventFilter.country.name == "Tanzanie" && (<>
        <Carousel fade controls={false} interval={3000} indicators={false} className="carousel">
          <Carousel.Item>  
            <div className="slide_item">
              <div className="overlay" style={{'backgroundImage':`url(/banners/tanzanie/sansibar.jpg)`}}></div>
              <h1>Zanzibar</h1>             
            </div> 
          </Carousel.Item>       
          <Carousel.Item>       
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/tanzanie/safari.jpg)`}}></div>
            <h1>Safari</h1>             
          </div> 
          </Carousel.Item>             
          <Carousel.Item>       
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/tanzanie/masai.jpg)`}}></div>
            <h1>Massai</h1>             
          </div> 
          </Carousel.Item>             
        </Carousel>
      </>)}
      {eventFilter.country.name == "Benin" && (<>
        <Carousel fade controls={false} interval={3000} indicators={false} className="carousel">
          <Carousel.Item>  
            <div className="slide_item">
              <div className="overlay" style={{'backgroundImage':`url(/banners/benin/hotel.jpg)`}}></div>
              <h1>Benin</h1>             
            </div> 
          </Carousel.Item>       
          <Carousel.Item>       
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/benin/memorial.jpg)`}}></div>
            <h1>Benin Memorial</h1>             
          </div> 
          </Carousel.Item>             
          <Carousel.Item>       
          <div className="slide_item">
            <div className="overlay" style={{'backgroundImage':`url(/banners/benin/ouidah.jpg)`}}></div>
            <h1>Ouidah</h1>             
          </div> 
          </Carousel.Item>             
        </Carousel>
      </>)}     
  </>)
}
