const AppConfig = {
  pay_cta: 'paid',
  donate_cta: 'donation',
  participate_cta: 'free',
  //
  //
  //
  appendCompostToEvents: (events) => {
    events = events.data.map((event)=>{
      event['billeterie'] = {};
      event['billeterie']['tickets'] = event.children?.tickets;
      event['billeterie']['compost'] = event.children?.keper;
      return event 
    })
    // synchronize tickets and compost
    events.map((event)=>{
     
      let tickets = event['billeterie']['tickets'];
      let compost = event['billeterie']['compost'];
      // if(compost){
        const compostObj = compost.reduce((old, cur)=>{
          if(!old[cur['order_item_id']] )
            old[cur['order_item_id']] = cur
          
            old[cur['order_item_id']]['compost'] = old[cur['order_item_id']]['compost'] > 0 ? ( old[cur['order_item_id']]['compost'] + parseInt(cur['quantity']) ): parseInt(cur['quantity']);
          //old[cur['order_item_id']]['compost'] = old[cur['order_item_id']]['compost'] > 0 ? ( old[cur['order_item_id']]['compost'] + parseInt(cur['quantity']) ): parseInt(cur['quantity']);
          return old
        },{});
        // console.log("COMPOST")
        // console.log(compostObj)
      // }
      tickets.map((ticket)=>{
        return ticket['compost'] = compostObj[ticket.id] ? compostObj[ticket.id]['compost'] : 0;
      })
      // console.log("tickets",tickets);
      // console.log("compost",compost);
      return event 
    })
    return events
  },
  appendCompostToOrders: (orders) => {
    orders = orders.data.map((event)=>{
      event['billeterie'] = {};
      event['billeterie']['tickets'] = event.children?.orderItems;
      event['billeterie']['compost'] = event.children?.keper;
      return event 
    })
    // synchronize tickets and compost
    orders.map((event)=>{
     
      let tickets = event['billeterie']['tickets'];
      let compost = event['billeterie']['compost'];
      // if(compost){
        const compostObj = compost.reduce((old, cur)=>{
          if(!old[cur['order_item_id']] )
            old[cur['order_item_id']] = cur
          
            old[cur['order_item_id']]['compost'] = old[cur['order_item_id']]['compost'] > 0 ? ( old[cur['order_item_id']]['compost'] + parseInt(cur['quantity']) ): parseInt(cur['quantity']);
          //old[cur['order_item_id']]['compost'] = old[cur['order_item_id']]['compost'] > 0 ? ( old[cur['order_item_id']]['compost'] + parseInt(cur['quantity']) ): parseInt(cur['quantity']);
          return old
        },{});
        // console.log("COMPOST")
        // console.log(compostObj)
      // }
      tickets.map((ticket)=>{
        return ticket['compost'] = compostObj[ticket.id] ? compostObj[ticket.id]['compost'] : 0;
      })
      // console.log("tickets",tickets);
      // console.log("compost",compost);
      return event 
    })
    return orders
  },
  getBilleterieMeta: (tickets) => {
    let billeterie_meta = tickets.reduce((state, current)=>{
      if(!state.hasOwnProperty(current['type']))
        state[current['type']] = {}

        if(state[current['type']][current['title']]){
          state[current['type']][current['title']]['vendus'] += current['total_seat'] ? parseInt(current['total_seat']) : 0;
          state[current['type']][current['title']]['recettes'] += current['total_price'] ? parseInt(current['total_price']) : 0;
          state[current['type']][current['title']]['composts'] += current['compost'] ? parseInt(current['compost']) : 0;

        } else {
          state[current['type']][current['title']] = {           
              vendus: current['total_seat'] ? parseInt(current['total_seat']) : 0,
              recettes: current['total_price'] ? parseInt(current['total_price']) : 0,
              composts: current['compost'] ? parseInt(current['compost']) : 0
          }
        }

      return state;
    },{ 
      [AppConfig['pay_cta']] : {}, 
      [AppConfig['donate_cta']] : {}, 
      [AppConfig['participate_cta']]: {}  
    });
    return billeterie_meta;
  },
  getTicketsMeta: (tickets)=>{
    return tickets.reduce((state, current)=>{
      if(!state[current['type']]) 
        state[current['type']] = []

      state[current['type']].push(current)
      return state
    },{});  
  }
}



export default AppConfig;