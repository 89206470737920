'use client';
import { useState, useEffect, Suspense} from 'react';
import ApiConfig from 'app/ApiConfig';
import AppConfig from 'app/AppConfig';
import moment from 'moment';

import styles from './single-event.module.css';
import Navbar from 'app/components/Navbar';
import { Dropdown, Modal, Button, Form, Table, Badge } from 'react-bootstrap';
import { Button as Btn, Grid } from 'antd';
import { faCircleLeft, faShareNodes, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';
import { WhatsappIcon, WhatsappShareButton, FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterIcon, TwitterShareButton } from 'react-share';

import SingleEventCard from 'app/components/single-event-card/single-event-card';
import Footer from 'app/components/Footer';

import { getUser } from 'app/user_state';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import MetaTags from 'app/components/MetaTags';
import MessageFormModal from 'app/messages/MessageFormModal';
import EventList from 'app/components/events/EventList';
import { useRecoilState } from 'recoil';
import { eventFilterState, default_event_filter } from 'app/states/event_state';
import set from 'lodash/fp/set'
import { useNavbarSize } from 'app/utils/useNavbarSize';

const { useBreakpoint } = Grid;

export default function SingleEvent() {
  const [user, setUser ] = useState(getUser());
  const {id: event_id} = useParams();
  const screen = useBreakpoint();
  const navbarSize = useNavbarSize();

  //const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    console.log("ID CHANGE");
  },[event_id])
   
    const [event, setEvent] = useState({});
    useEffect(()=>{
      const fetchEvents = async () => {
        const res = await fetch(`${ApiConfig.events}${event_id}`);
        const eventRes = await res.json();
        setEvent(eventRes.data);
        attachTicketsData(eventRes.data);
      }
      fetchEvents().catch(e=>console.log(e));
    },[])
  
    
    const [eventCTA, setEventCTA ] = useState({
      [AppConfig.participate_cta]: false,
      [AppConfig.pay_cta]:false,
      [AppConfig.donate_cta]: false
    });
    const [payTickets, setPayTickets] = useState([]);
    const [participateTickets, setParticipateTickets] = useState([]);
    const [donateTickets, setDonateTickets] = useState([]);
    const attachTicketsData = (event) => {
              // ticket cta settings
              let ticket_infos = event.ticket_infos;
              let pay_tickets = [];
              let participate_tickets = [];
              let donate_tickets = [];
              for(let i = 0; i < ticket_infos.length; i++){
                let type = ticket_infos[i].type         
                if(type == AppConfig.pay_cta){
                  setEventCTA((old)=>{ 
                    //cta = 'Payer'
                    return {...old, [AppConfig.pay_cta] : true }
                  })
                  pay_tickets.push(ticket_infos[i]);
                }
                if(type == AppConfig.participate_cta){
                  setEventCTA((old)=>{ 
                   //cta = 'Je m\'inscris'
                    return {...old, [AppConfig.participate_cta] : true }
                  })
                  participate_tickets.push(ticket_infos[i]);
                  
                }
                if(type == AppConfig.donate_cta){
                  setEventCTA((old)=>{ 
                    // cta = 'Je participe'
                    return {...old, [AppConfig.donate_cta] : true }
                  })
                  donate_tickets.push(ticket_infos[i]);
                }           
              } 
              setPayTickets(pay_tickets)      
              setParticipateTickets(participate_tickets) 
              setDonateTickets(donate_tickets);
    } 
    const [eventFilter, setEventFilter] = useRecoilState(eventFilterState);
    useEffect(()=>{
      if(event.category_id){
        let current_filter = {...default_event_filter, country: eventFilter.country}
        setEventFilter(set('search.category_id', event.category_id, current_filter));
      }
    },[event])
    // // events
    // const [events, setEvents] = useState([])
    // // paginte events
    // const [canLoad, setCanLoad] = useState(true);
    // const [eventPage, setEventPage] = useState(1);
    // const [perPage, setPerPage] = useState(8);
    // // initial events load
    // useEffect(()=>{
    //   setCanLoad(true);
    //   setEventPage(1);
    //   const fetchEvents = async () => {
    //     let url = `${ApiConfig.events}`;
    //     // filtres
    //     let filter = {
    //       'category_id': event.category_id
    //     };
    //     if( Object.keys(filter).length){
    //       let filter_url = encodeURIComponent(JSON.stringify(filter));
    //       url = `${url}?filtre=${filter_url}`
    //     }      
    //     const res = await fetch(url);
    //     const eventRes = await res.json();
    //     setEvents(eventRes.data);
    //     //attachTicketsData(eventRes.data);
    //   }
    //   fetchEvents().catch(e=>console.log(e));
    // },[event])
    // // paginate loader
    // useEffect(()=>{
    //   if(event && eventPage > 1 && canLoad) {
    //     const fetchEvents = async () => {
    //       let url = `${ApiConfig.events}`;
          
    //       // pagination
    //       let start = (eventPage - 1) * perPage ;
    //       let end = eventPage * perPage;
    //       url += `?_start=${start}&_end=${end}`

    //       // filtres
    //       let filter = {
    //         'category_id': event.category_id
    //       };
    //       // if(localisationSearch){
    //       //   filter['location_data'] = {};
    //       //   filter['location_data']['locationMeta'] = localisationSearch
    //       // }
    //       // if(dateSearch){
    //       //   filter['date_time'] = {};
    //       //   filter['date_time']['date_start'] = dateSearch
    //       // }
    //       if( Object.keys(filter).length){
    //         let filter_url = encodeURIComponent(JSON.stringify(filter));
    //         url = `${url}&filtre=${filter_url}`
    //       }          
    //       // request
    //       const res = await fetch(url);
    //       const eventsRes = await res.json();
    //       if(eventsRes.data.length == 0 ) { // no more items to load
    //         setCanLoad(false)
    //       } else { // received items from request
    //         setEvents((old)=> {
    //           //console.log("EVENT :")
    //           let current = [...old];
    //           for(let i = 0; i< eventsRes.data.length; i++) {
    //             let event = eventsRes.data[i];
    //             //console.log(event);
    //             if(!current.some((elem)=> event.id == elem.id)) {
    //               current.push(event)
    //             }
    //           }
    //           //console.log(current)  ;
    //           return current
    //         });
    //       }
    //     }
    //     fetchEvents().catch(e=>console.log(e));
    //   }
    // },[eventPage])
    // //
    // const loadMoreEvent = (e)=>{
    //   e.preventDefault();
    //   setEventPage(old=> old +1);
    // }
    // //

    //
    //
  //  messages modal
	const [showMessageFormModal, setShowMessageFormModal] = useState(false);
  
  // open message modal
  const openMessageFromModal = (e) => {
    //e.preventDefault();
    setShowMessageFormModal(true)
  }

  // order modals modals
  const [showPayModal, setShowPayModal] = useState(false);
  const [showParticipateModal, setShowParticipateModal] = useState(false);
  const [showDonateModal, setShowDonateModal] = useState(false);
  const handleClose = () => {
    setShowPayModal(false);
    setShowParticipateModal(false);
    setShowDonateModal(false);
    //setTicketCommande({})
    setNomPrenom("")
    setAdresse("")
    setNumPhone("")
  }
  const handleModal = (e, modalTarget) =>{
    e.preventDefault();
   
    if(modalTarget == AppConfig.pay_cta){
      setShowPayModal(true)
    }
    if(modalTarget == AppConfig.participate_cta){
      setShowParticipateModal(true)
    }
    if(modalTarget == AppConfig.donate_cta){
      setShowDonateModal(true)
    }
  }
  const handleInputChange = (e, setter) => {
    setter(e.target.value);
  }
  // order modals meta data
  const [nomPrenom, setNomPrenom] = useState("")
  const [adresse, setAdresse] = useState("")
  const [numPhone, setNumPhone] = useState("")
  const [paymentMethod, setPaymentMethod] = useState("")
  const [ticketCommande, setTicketCommande] = useState({});
  const handleTicketCommandChange = (e, ticket, opts = null) =>{
    console.log("Ticket command change")
    //e.preventDefault();
    if(ticket.type == AppConfig.pay_cta){
      let quantity = e.target?.value || e || "0";
      if(opts?.increment)
        quantity = parseInt(quantity) + 1
      if(opts?.decrement)
        quantity = parseInt(quantity) > 0 ? parseInt(quantity) - 1 : 0;
      let price = parseInt(ticket.price) || 0;
      let total_price = parseInt(quantity) * parseInt(price)
      let ticket_cmd = {...ticket, total_price, 'total_seat': quantity}
      setTicketCommande((old)=>{
        return {...old, [ticket.title]: ticket_cmd}
      })
    }
    
    if(ticket.type == AppConfig.donate_cta) {
      let quantity = e.target?.value || e || "0";
      if(opts?.increment)
        quantity = parseInt(quantity) + 1
      if(opts?.decrement)
        quantity = parseInt(quantity) > 0 ? parseInt(quantity) - 1 : 0;
      let total_price = parseInt(quantity)
      let ticket_cmd = {...ticket, total_price}
      setTicketCommande((old)=>{
        return {...old, [ticket.title]: ticket_cmd}
      })
    }
    if(ticket.type == AppConfig.participate_cta) {
      let quantity = e.target?.value || e || "0";
      if(opts?.increment)
        quantity = parseInt(quantity) + 1
      if(opts?.decrement)
        quantity = parseInt(quantity) > 0 ? parseInt(quantity) - 1 : 0;
      let total_seat = parseInt(quantity)
      let ticket_cmd = {...ticket, total_seat}
      setTicketCommande((old)=>{
        return {...old, [ticket.title]: ticket_cmd}
      })
    }
  }
  const getCommandeTotal = (ticketCommande, total_type) => {
    let total = 0;
    for(let key in ticketCommande){
      total += parseInt(ticketCommande[key][total_type])
    }
    return total
  }
  const getPayCommandeRequest = () => {
    let products = [];
    for(let key in ticketCommande){
      let p = ticketCommande[key];
      products.push(p);
    }
    return {
      'customer_id': user?.uid || "unknown_user",
      // payment_method
      'client_name' : nomPrenom,
      'client_phone' : numPhone,
      //'client_address': adresse,
      'products': products,
      'total_price': getCommandeTotal(ticketCommande,'total_price'),
      'total_seat': getCommandeTotal(ticketCommande,'total_seat'),
      'payment_method': paymentMethod,
      'event_id': event_id
    };
  }
  const getDonateCommandeRequest = () => { 
    let products = [];
    for(let key in ticketCommande){
      let p = ticketCommande[key];
      products.push(p);
    }
    return {
      'customer_id': user?.uid || "unknown_user",
      'client_name' : nomPrenom,
      'client_phone' : numPhone,
      //'client_address': adresse,
      'products': products,
      'total_price': getCommandeTotal(ticketCommande,'total_price'),
      'payment_method': paymentMethod,
      'event_id': event_id
    };
  }
  const getParticipateCommandeRequest = () => {   
    let products = [];
    for(let key in ticketCommande){
      let p = ticketCommande[key];
      products.push(p);
    } 
    return {
      'customer_id': user?.uid || "unknown_user",
      'client_name' : nomPrenom,
      'client_phone' : numPhone,
     // 'client_address': adresse,
      'total_seat': getCommandeTotal(ticketCommande,'total_seat'),
      'products': products,
      'event_id': event_id
    };
  }
  const handleSaveCommand = async (e, ticket_type) => {
    // console.log("COMMANDE REQUEST");
    let commande_request = null;
    if(ticket_type == AppConfig.pay_cta){      
      commande_request = getPayCommandeRequest()
    }
    if(ticket_type == AppConfig.participate_cta){      
      commande_request = getParticipateCommandeRequest();
    }
    if(ticket_type == AppConfig.donate_cta) {      
      commande_request = getDonateCommandeRequest()
    }
    // console.log(commande_request);
    const submitOrderReq = await fetch(ApiConfig.create_order, {
      method: "POST",
      body:  JSON.stringify({...commande_request,'event_name':event.title, 'venue': event.location_data?.locationMeta}),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const responseSubmitOrder = await submitOrderReq.json();   
    if(responseSubmitOrder.url){
      window.open(responseSubmitOrder.url, "_blank");
    }
    
  }
  // geoloc
  useEffect(()=>{
    if (navigator.geolocation) {
      const handlePermission = async () => {
        const result = await navigator.permissions.query({ name: "geolocation" })
        // if(result.state != 'denied'){
        //   navigator.geolocation.getCurrentPosition((position)=>{
        //     const latitude = position.coords.latitude;
        //     const longitude = position.coords.longitude;
    
        //     console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
        //   }, (e)=>{
        //     console.log("geoloc error", e)
        //   });
        //}
        console.log(result);
      }
      handlePermission().catch(e=>console.log("handle Permission error",e));
      
    } else {
      console.log("Geolocation not supported");
    }
  },[])
  // map
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627
    },
    zoom: 11
  };
  const AnyReactComponent = ({ text }) => (
    <div className="map_marker"></div>
  );
  const [mapCenter, setMapCenter] = useState(defaultProps.center);
  const handleMapDrag = (map) => {
    // console.log(map);
    // console.log("LATITUDE ", map.center.lat())
    // console.log("LONGITUDE ", map.center.lng())
    setMapCenter({lat: map.center.lat(), lng: map.center.lng()})
  }

  return (
    <>
      <MetaTags title={event.title} description={event.resume} image={event.image_link} url={window.location.href}/>
      <Navbar />
      <div style={{paddingTop: navbarSize?.height}}></div>
      {(screen.xs || screen.sm) && <div className={'page_section'}><Btn className="get_back_btn" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faCircleLeft}/>Retour</Btn></div>}
      <section className={`page_section ${styles.event_header} `}>    
      {/* {!screen.md && <Btn className="get_back_btn" onClick={() => navigate(-1)}><FontAwesomeIcon icon={faCircleLeft}/>Retour</Btn>} */}
        <div className={`${styles.event_presentation}`}>
          <h1 className={`${styles.event_title}`}>{event.title}</h1>
          {/* <p dangerouslySetInnerHTML={{ __html: event.resume }}></p> */}
          <p>{ event.resume }</p>
          <p className="event_author_bar"><span className={`${styles.event_presentation_date}`}>{moment(event.date_time?.date_start).format('D MMMM YYYY')}</span> par {event.owner_name} <button className="msg_button" onClick={openMessageFromModal}><FontAwesomeIcon icon={faEnvelope}/> Contact</button> </p>
          <p className={`${styles.event_presentation_location}`}>
            <img src="/icons/place.png" alt="" />
            <span className="p10">{event.location_data?.locationMeta}</span>
          </p>
          <div className="single_event_cta_block">
            <button className={`${styles.event_btn_interesse}`}>                        
              <span className="p10">Interessé</span>
              {/* <span className="p10"><img src="/icons/interested.png" alt="" />  Interessé</span> */}
            </button>
            {Object.values(eventCTA).filter(e=>e==true).length == 1 && eventCTA[AppConfig.pay_cta] && 
              <Button className={`${styles.event_btn_cta}`} onClick={e=>handleModal(e,AppConfig.pay_cta)}>Payer</Button> }
            {Object.values(eventCTA).filter(e=>e==true).length == 1 && eventCTA[AppConfig.participate_cta] && 
               <Button className={`${styles.event_btn_cta}`} onClick={e=>handleModal(e,AppConfig.participate_cta)}>Je m'inscris</Button> }
            {Object.values(eventCTA).filter(e=>e==true).length == 1 && eventCTA[AppConfig.donate_cta] && 
               <Button className={`${styles.event_btn_cta}`} onClick={e=>handleModal(e,AppConfig.donate_cta)}>Participer</Button> }
            
            {Object.values(eventCTA).filter(e=>e==true).length > 1 && ( <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-basic" className={`${styles.event_btn_cta}`}>
               Participer
              </Dropdown.Toggle>

              <Dropdown.Menu >
                {eventCTA[AppConfig.pay_cta] && 
                  <Dropdown.Item onClick={e=>handleModal(e,AppConfig.pay_cta)}>Payer</Dropdown.Item>}
                {eventCTA[AppConfig.participate_cta] && 
                  <Dropdown.Item onClick={e=>handleModal(e,AppConfig.participate_cta)}>Je m'inscris</Dropdown.Item>}
                {eventCTA[AppConfig.donate_cta] && 
                  <Dropdown.Item onClick={e=>handleModal(e,AppConfig.donate_cta)}>Participer</Dropdown.Item>}              
              </Dropdown.Menu>
            </Dropdown> )}
          </div>
          <p>44 abonnées</p>
          <div className="share_button_bar">
            <p>Partager <FontAwesomeIcon icon={faShareNodes} style={{color: '#6AB50C'}}/></p>
            <WhatsappShareButton url={window.location.href}><WhatsappIcon size={32} round={true} /></WhatsappShareButton>
            <FacebookShareButton url={window.location.href}><FacebookIcon size={32} round={true} /></FacebookShareButton>
            <TwitterShareButton url={window.location.href}><TwitterIcon size={32} round={true} /></TwitterShareButton>
            <LinkedinShareButton url={window.location.href}><LinkedinIcon size={32} round={true} /></LinkedinShareButton>
          </div>
        </div>
        <div className={`img_event_header`} style={{backgroundImage: `url(${event.image_link ? event.image_link : "https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&"})`}}>
          {/* <img
            loading="lazy"
            src={`${event.image_link ? event.image_link : "https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/e07bab543b990b5034d869014f8c83871ba9596a771627b3539e34a101318679?apiKey=52aeea5753104c6b836e329f8e3d046f&"}`}
            className="img "
          /> */}
        </div>
      </section>
      <section className={`page_section`}>
        <article className={`page_event_left`}>
          <h1>Date et heure</h1>
          <br />
          <div className={`${styles.desc_meta}`}>
            <p className={`${styles.desc_meta_item}`}> 
              <img className="cta_block" src="/icons/hour.png" alt="" />
              <span style={{fontWeight: 700}}>
                {/* 1 jour 4 heures */}
               { moment(event.date_time?.date_start).format('D MMMM YYYY')} 
               <span> - </span>
               { moment(event.date_time?.date_end).format('D MMMM YYYY') } 
              </span>
            </p>
            <p className={`${styles.desc_meta_item}`}> 
              <img className="cta_block" src="/icons/ticket.png" alt="" /> 
              <strong>Type de billet : Billet électronique</strong>
            </p>
          </div>
          <br /> 
          <h1>Description</h1>
          <p dangerouslySetInnerHTML={{ __html: event.description }}></p>
  {/*  */}
         {event.tags && (
          <>
          <h1>Mots clés</h1>                
            <ul className="event_motscles">
              {event.tags.map((tag)=>{
                return <li key={tag.id}>{tag}</li>      
              })}         
            </ul>
          </>)}
    
      {/* <section className={`page_section`}>
        <h1>Politique de remboursements</h1>
        <p>Pas de politique de remboursement</p>
      </section> */}
    {/*  */}
        <h1>Afficher sur la carte</h1>
        <br />
        <section className={`${styles.event_map_section}`}>
          <div className={`${styles.event_map}`}>   
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyCunMwmsul0Ar6es8Pd43hZiBFfMe-mYlw" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
              onDrag={handleMapDrag}
            >
              <AnyReactComponent
                lat={mapCenter.lat}
                lng={mapCenter.lng}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>      
        </section>
        </article>
        <aside  className={`page_event_right`}>
          <div className={`${styles.event_price}`}>            
            <h3 style={{textAlign: 'center'}}>Participer</h3>
            <div className={`${styles.event_price_list}`}>
              {payTickets && payTickets.map((ticket)=>{    
                let is_active = ticketCommande[ticket.title] && parseInt(ticketCommande[ticket.title]['total_seat']) > 0;
                return (
                  <div className="buy_shortcut_bar">
                    <strong className="buy_shortcut_ticket_name">{ticket.title}</strong>
                    <div>
                    <button className={`buy_shortcut_quantity_button ${!is_active ? 'disabled' : ''}`}
                    
                    onClick={(e)=>{   
                      let input = document.getElementById(`${ticket.id}_input`);
                      handleTicketCommandChange(input.value, ticket, {decrement:true})  
                    }}>-</button>
                    <input type="number" className="buy_shortcut_input" name="" id={`${ticket.id}_input`} 
                    value={ticketCommande[ticket.title] ? ticketCommande[ticket.title]['total_seat']: '0'}
                    onChange={e=>handleTicketCommandChange(e,ticket)} />
                    <button className='buy_shortcut_quantity_button' onClick={(e)=>{   
                      let input = document.getElementById(`${ticket.id}_input`);
                      handleTicketCommandChange(input.value, ticket, {increment:true})  
                    }}>+</button>
                    </div>
                </div>
                )
              })}
              {/* {participateTickets && participateTickets.map((ticket)=>{
                return (
                  <>
                  <div>{ticket.title}</div>
                  <div>
                  <button onClick={(e)=>{   
                    let input = document.getElementById(`${ticket.id}_input`);
                    handleTicketCommandChange(input.value, ticket, {decrement:true})  
                  }}>-</button>
                    <input type="number" style={{width:"80px"}} name=""  id={`${ticket.id}_input`}  
                   value={ticketCommande[ticket.title] ? ticketCommande[ticket.title]['total_seat']: '0'}
                  onChange={e=>handleTicketCommandChange(e,ticket)} />
                  <button onClick={(e)=>{   
                    let input = document.getElementById(`${ticket.id}_input`);
                    handleTicketCommandChange(input.value, ticket, {increment:true})  
                  }}>+</button>
                  </div>
                </>
                )
              })} */}
              {/* {donateTickets && donateTickets.map((ticket)=>{
                return (
                  <>
                  <div>{ticket.title}</div>
                  <div>
                  <button onClick={(e)=>{   
                    let input = document.getElementById(`${ticket.id}_input`);
                    handleTicketCommandChange(input.value, ticket, {decrement:true})  
                  }}>-</button>
                    <input type="number" style={{width:"80px"}} name=""  id={`${ticket.id}_input`} 
                   value={ticketCommande[ticket.title] ? ticketCommande[ticket.title]['total_price']: '0'}
                  onChange={e=>handleTicketCommandChange(e,ticket)} />
                  <button onClick={(e)=>{   
                    let input = document.getElementById(`${ticket.id}_input`);
                    handleTicketCommandChange(input.value, ticket, {increment:true})  
                  }}>+</button>
                  </div>
                </>
                )
              })} */}
            </div>   
          </div>        
          {Object.values(eventCTA).filter(e=>e==true).length == 1 && eventCTA[AppConfig.pay_cta] && 
            <Button className={`${styles.event_cta_reserv}`} onClick={e=>handleModal(e,AppConfig.pay_cta)}>Payer</Button> }
          {Object.values(eventCTA).filter(e=>e==true).length == 1 && eventCTA[AppConfig.participate_cta] && 
              <Button className={`${styles.event_cta_reserv}`} onClick={e=>handleModal(e,AppConfig.participate_cta)}>Je m'inscris</Button> }
          {Object.values(eventCTA).filter(e=>e==true).length == 1 && eventCTA[AppConfig.donate_cta] && 
              <Button className={`${styles.event_btn_cta}`} onClick={e=>handleModal(e,AppConfig.donate_cta)}>Participer</Button> }
          
          {Object.values(eventCTA).filter(e=>e==true).length > 1 && ( <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic" className={`${styles.event_cta_reserv} btn-fit`}>
            Réserver
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {eventCTA[AppConfig.pay_cta] && 
                <Dropdown.Item onClick={e=>handleModal(e,AppConfig.pay_cta)}>Payer</Dropdown.Item>}
              {eventCTA[AppConfig.participate_cta] && 
                <Dropdown.Item onClick={e=>handleModal(e,AppConfig.participate_cta)}>Je m'inscris</Dropdown.Item>}
              {eventCTA[AppConfig.donate_cta] && 
                <Dropdown.Item onClick={e=>handleModal(e,AppConfig.donate_cta)}>Je participe</Dropdown.Item>}              
            </Dropdown.Menu>
          </Dropdown> )}          
        </aside>        
      </section>



      <section className={`page_section ${styles.autres_events_section}`}>
        <h1>Autres destinations que vous pourriez aimer</h1>
        <br />
        {/* <div className={`${styles.events_list}`}>
          {events.map((event)=>{
            return (
              <SingleEventCard event={event} key={event.id} />     
            )
          })}          
        </div> 
        <div className="load_more_row" style={{textAlign: "center"}}><button className="btn load_more_btn" onClick={loadMoreEvent} disabled={!canLoad}>Voir plus d'évènements</button></div>        
        */}
        <Suspense fallback={<p>Chargement....</p>}>
            <EventList/>
          </Suspense>
      </section>
      <Footer/>
      {/* MESSAGES MODAL */}
      <MessageFormModal   
        planner_id={event.owner_id} 
        sender_id={user?.uid || 'unknown_user'} 
        showMessageFormModal={showMessageFormModal}
        setShowMessageFormModal={setShowMessageFormModal}
      />
      {/* PAY MODAL */}
      <Modal show={showPayModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>PAYER</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nom / Prénom</Form.Label>
            <Form.Control type="text" placeholder="Nom/Prenom" value={nomPrenom} onChange={(e)=> handleInputChange(e, setNomPrenom)} />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Adresse</Form.Label>
            <Form.Control type="text" placeholder="Adresse" value={adresse} onChange={(e)=> handleInputChange(e, setAdresse)} />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Num téléphone</Form.Label>
            <Form.Control type="text" placeholder="Telephone" value={numPhone} onChange={(e)=> handleInputChange(e, setNumPhone)} />
          </Form.Group>
              
          {payTickets && 

          (<>
            <p>Achat de ticket(s)</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Ticket</th>
                  <th>Prix</th>
                  <th>Quantité</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
              {payTickets && payTickets.map((ticket, index)=>{
                return (<tr key={ticket.id}>
                  <td>{ticket.title}</td>
                  <td>{ticket.price}</td>
                  <td><input type="number" style={{width:"80px"}} name="" id="" 
                  value={ticketCommande[ticket.title] ? ticketCommande[ticket.title]['total_seat']: '0'}
                   onChange={e=>handleTicketCommandChange(e,ticket)} /></td>
                  <td>{(ticketCommande && ticketCommande[ticket.title]) && ticketCommande[ticket.title]['total_price']}</td>
                </tr>)
              })}
              </tbody>
            </Table>
          </>)}           
          <h3><Badge bg="light" text="dark">Nombre de ticket : {ticketCommande && getCommandeTotal(ticketCommande, 'total_seat')}</Badge></h3>
          <h3><Badge bg="success"> Prix total : {ticketCommande && getCommandeTotal(ticketCommande, 'total_price')}</Badge></h3>                   
          <div className={styles.form_inline_group}>
            <label htmlFor="wave_payment" style={{textAlign:"center"}}><img className="payment_method_icon" src="/images/wave.png"/><br/> <input type="radio" name="payment_method" id="wave_payment" onChange={(e)=>{setPaymentMethod("wave")}} /> </label>
            <label htmlFor="om_payment" style={{textAlign:"center"}}><img className="payment_method_icon" src="/images/om.png"/><br/>  <input type="radio" name="payment_method" id="om_payment" onChange={(e)=>{setPaymentMethod("om")}} /> </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={e => handleSaveCommand(e, AppConfig.pay_cta)}>
            Payer
          </Button>
        </Modal.Footer>
      </Modal>
      {/* PARTICIPATE MODAL */}
      <Modal show={showParticipateModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>PARTICIPATE MODAL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nom / Prénom</Form.Label>
            <Form.Control type="text" placeholder="Nom/Prenom" value={nomPrenom} onChange={(e)=> handleInputChange(e, setNomPrenom)} />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Adresse</Form.Label>
            <Form.Control type="text" placeholder="Adresse" value={adresse} onChange={(e)=> handleInputChange(e, setAdresse)} />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Num téléphone</Form.Label>
            <Form.Control type="text" placeholder="Telephone" value={numPhone} onChange={(e)=> handleInputChange(e, setNumPhone)} />
          </Form.Group>
          {participateTickets && (<>
            <p>Nombre de place(s)</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Ticket</th>
                  <th>Quantité</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {participateTickets && participateTickets.map((ticket, index)=>{
                  return (<tr key={ticket.id}>
                      <td><strong>{ticket.title}</strong></td>
                      <td><input type="number" name="" id="" 
                      value={ticketCommande[ticket.title] ? ticketCommande[ticket.title]['total_seat']: '0'}
                      onChange={e=>handleTicketCommandChange(e,ticket)} /></td>
                      <td>{(ticketCommande && ticketCommande[ticket.nom]) && ticketCommande[ticket.nom]['total']}</td>
                    </tr>)
                  })}
              </tbody>
            </Table>
          </>)}         
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={e => handleSaveCommand(e, AppConfig.participate_cta)}>
            S'inscrire
          </Button>
        </Modal.Footer>
      </Modal>
      {/* DONATE MODAL */}
      <Modal show={showDonateModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Donate MODAL</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Nom / Prénom</Form.Label>
            <Form.Control type="text" placeholder="Nom/Prenom" value={nomPrenom} onChange={(e)=> handleInputChange(e, setNomPrenom)} />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Adresse</Form.Label>
            <Form.Control type="text" placeholder="Adresse" value={adresse} onChange={(e)=> handleInputChange(e, setAdresse)} />
          </Form.Group> */}
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Num téléphone</Form.Label>
            <Form.Control type="text" placeholder="Telephone" value={numPhone} onChange={(e)=> handleInputChange(e, setNumPhone)} />
          </Form.Group>
          {donateTickets && (<>
            <p>Montant de participation</p>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Ticket</th>
                  <th>Donation</th>
                </tr>
              </thead>
              <tbody>
              {donateTickets && donateTickets.map((ticket, index)=>{ 
                return (
                <tr key={ticket.id}>
                    <td><label htmlFor=""><span>{ticket.title}</span></label></td>
                    <td><input type="number" name="" id="" placeholder="Montant_Donation" 
                     value={ticketCommande[ticket.title] ? ticketCommande[ticket.title]['total_price']: '0'}
                    onChange={(e)=> handleTicketCommandChange(e,ticket)}/></td>
                  </tr>
                  )}
                )} 
              </tbody>
            </Table>
          </>)}      
          <div className={styles.form_inline_group}>
            <label htmlFor="wave_payment" style={{textAlign:"center"}}><img className="payment_method_icon" src="/images/wave.png"/><br/> <input type="radio" name="payment_method" id="wave_payment" onChange={(e)=>{setPaymentMethod("wave")}} /> </label>
            <label htmlFor="om_payment" style={{textAlign:"center"}}><img className="payment_method_icon" src="/images/om.png"/><br/>  <input type="radio" name="payment_method" id="om_payment" onChange={(e)=>{setPaymentMethod("om")}} /> </label>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Fermer
          </Button>
          <Button variant="primary" onClick={e => handleSaveCommand(e, AppConfig.donate_cta)}>
            Participer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
