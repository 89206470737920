import { listEvents } from 'app/ressources/events';
import { atom, selector } from 'recoil';
import _get from 'lodash/fp/get'
import _set from 'lodash/fp/set'

export const eventSearchFilterState = atom({
  key: 'eventSearchFilterState',
  default: {
    search: {
      location_data: {       
        locationMeta:"",      
      },
      date_time:{        
        date_start:"", 
      }
    }
  }
});
export const eventSearchFilterListener = selector({
  key:'eventSearchFilterListener',
  get: ({get, set}) => {
    let search = get(eventSearchFilterState);
    let filter = get(eventFilterState);
    let newfilter = {...filter, 'search': search}
    set(eventFilterState, newfilter)
  }
})
export const eventPaginationFilterState = atom({
  key: 'eventPaginationFilterState',
  default: {
    pagination: {
      page: 1,
      perPage: 1
    }
  }
});
export const eventPaginationFilterListener = selector({
  key:'eventPaginationFilterListener',
  get: ({get, set}) => {
    let pagination = get(eventPaginationFilterState);
    let filter = get(eventFilterState);
    let newfilter = {...filter, 'pagination': pagination}
    set(eventFilterState, newfilter)
  }
})
export const eventRegionFilterState = atom({
  key: 'eventRegionFilterState',
  default: {
    country: {
      name: 'sn',
    }
  }
});
export const eventRegionFilterStateListener = selector({
  key:'eventRegionFilterStateListener',
  get: ({get, set}) => {
    let country = get(eventRegionFilterState);
    let filter = get(eventFilterState);
    let newfilter = {...filter, 'country': country}
    set(eventFilterState, newfilter)
  }
})

// event filter
export const default_event_filter = {
  search: {
    location_data: {       
      locationMeta:"",      
    },
    date_time:{        
      date_start:"", 
    },
    category_id: "",
  },
  pagination: {
    page: 1,
    perPage: 1
  },
  country: {
    name: 'Senegal',
  }
};
// App settings for country
export const countryState = selector({
  key: 'countryState',
  get: ({get}) => {
    let {country} = get(eventFilterState)
    return country
  },
  set: ({set}, val) => {
    set(eventFilterState, {...default_event_filter, 'country': val})
  }
})

export const eventFilterState = atom({
  key: 'eventFilterState',
  default: default_event_filter
})

export const eventFilteredList = selector({
  key:'eventFilteredList',
  get:  async ({get}) => {
    let filter = get(eventFilterState)
    const events = await listEvents(filter.search, filter.pagination.page, filter.pagination.perPage)
    return {events, filter};
  }
})


// EVENT FORM 
export const createEventForm = atom({
    key: 'createEventForm', // unique ID (with respect to other atoms/selectors)
    default: {
      file: null,

      title: "",
      owner_id: "",
      owner_name: "",
      type_id: "",
      category_id: "",
      subcategory_id: "",
      tags: [],
      location_data: {
        location: "Lieu", 
        locationMeta:"",
        location_id: "",
        latitude: null,
        longitude: null,
        country: ""
      },
      transportation_data:{  
        transport_id: "",
        transport_name: ""
      },
      date_time:{ 
        frequency: "once", 
        date_start:"", 
        time_start:"", 
        date_end:"", 
        time_end:""
      },
      maximum_capacity: "",

      //
      summary: "",
      description:"",

      ticket_infos: [],

      publish_data: {
        date_start:"",
        time_start:"",
        publish_time: "",
        is_public: ""
      }
    }, // valeur par défaut (alias valeur initials)
  });

export const createEventSteps = atom({
  key: 'createEventStep',
  default: 0
});