import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import CreateTransportationModalContent from './CreateTransportationModalContent';

export default function TransportationFormModal({showCreateTransportationModal, setShowCreateTransportationModal, currentTransport, }) {
  
  const handleClose = () => {
    setShowCreateTransportationModal(false);
  }
  
  return (
    <Modal show={showCreateTransportationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Informations de base</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateTransportationModalContent currentTransport={currentTransport} onClose={handleClose}/>        
        </Modal.Body>       
      </Modal>  
  )
}
