import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarDays, faCalendarPlus, faCircleQuestion, faMagnifyingGlass, faRightToBracket, faRotate, faSquarePlus, faTicket, faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { getUser, logout } from 'app/user_state';

import { Button } from 'react-bootstrap';
import UserMenu from './UserMenu';
import { Drawer, Menu } from 'antd';
import SelectCountry from 'app/components/countries/SelectCountry';

import { useNavigate } from 'react-router-dom';

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}



export default function Navbar() {


  const [showMenu, setShowMenu] = useState(false)
  const navRef = useRef(null)

  useEffect(()=>{ 
    console.log("NAVREF")
    console.log(navRef);
  },[window])
  
  const toggleMenu = () => {
    setShowMenu((val)=>!val);
  }

  const onClose = () => {
    setShowMenu(false);
  }
  
  const [user, setUser ] = useState(getUser());

  const navigate = useNavigate();
  const handleLogout = (e) => {
    e.preventDefault();
    if(logout()){
      console.log("should logout");
      window.location.reload()
    }
  }

  // v2 menu
  const onClick = (e) => {
    console.log('click ', e);
  };
  const menu_items = [
    getItem( <p className={'menu_link_url'}  onClick={e=>navigate('/a-propos')}>A propos</p>, 'sub1', <FontAwesomeIcon icon={faCircleQuestion} className="menu_icon" />),
    // logged in
    user && {type: 'divider'},
    user && getItem(<p className={'menu_link_url'} onClick={e=>navigate('/admin/events/generate')}>Créer un évènement</p>, 'sub2', <FontAwesomeIcon icon={faCalendarPlus} className="menu_icon" />),
    {type: 'divider'},
    user && getItem(<p className={'menu_link_url'} onClick={e=>navigate('/user/messages')}>Messages</p>, 'sub3', <FontAwesomeIcon icon={faEnvelope} className="menu_icon" />),
    {type: 'divider'},
    user && getItem(<p className={'menu_link_url'} onClick={e=>navigate('/user/tickets')}>Mes Tickets</p>, 'sub3', <FontAwesomeIcon icon={faTicket} className="menu_icon" />),
    user && {type: 'divider'},
    user && getItem(<p className={'menu_link_url'} onClick={e=>navigate('/user/events')}>Mes Events</p>, 'sub4', <FontAwesomeIcon icon={faCalendarDays} className="menu_icon" />),
    user && {type: 'divider'},
    user && getItem(<p className={'menu_link_url'} onClick={handleLogout}>Deconnexion</p>, 'sub5', <FontAwesomeIcon icon={faRotate} className="menu_icon" />),    
    // anonym
    !user && {type: 'divider'},
    !user && getItem(<p className={'menu_link_url'} onClick={e=>navigate('/login')}>S'inscrire</p>, 'sub6', <FontAwesomeIcon icon={faSquarePlus} className="menu_icon" />),    
    !user && {type: 'divider'},
    !user && getItem(<p className={'menu_link_url'} onClick={e=>navigate('/login')}>Connexion</p>, 'sub7', <FontAwesomeIcon icon={faRightToBracket} className="menu_icon" />),    
  ];
  return (
      <div id="navbar" className='page_section'>
        <aside id="first_block">
          <Link to="/">
            <img 
            src="/kati360.png"
            id="logo"
            />
          </Link>
          <span id="menu_search_holder"> <input type="text" name="" id="menu_search" placeholder="Rechercher" /><FontAwesomeIcon icon={faMagnifyingGlass} className="search_icon" /></span>
        </aside>
        <nav id="menu" className={`md menu_items`}>
          <ul>
            <li><Link to="/a-propos">A propos</Link></li>
            <li><Link to="/admin/events/generate">Créer un évènement</Link></li>
            <li> <SelectCountry display="select"/></li>
            {(user && (
            <li>
                <UserMenu user={user}/>
            </li>
            ))} 
            {!user &&
            (
              <>         
                <li><Link to="/login">S'inscrire</Link></li>
                <li><Link to="/login">Connexion</Link></li>
              </> 
            )}
          </ul>
        </nav>
        {/* PHONE DISPLAY */}
        <nav className={`sm`} onClick={toggleMenu}>
          <img src="/icons/menu.png" alt=""  />
        </nav>
        <Drawer
          title={<p style={{textAlign: 'center'}}><img src="/kati360.png" width="70" height="70"/><br/><strong>EventKati360</strong></p>}
          placement='left'
          closable={true}
          onClose={onClose}
          open={showMenu}
          key='left'
        >
          <Menu
            onClick={onClick}
            style={{
              width: '100%',
              border: 0
            }}
            //defaultSelectedKeys={['1']}
            //defaultOpenKeys={['sub1']}
            mode="inline"
            items={menu_items.filter((m)=> m != true)}
          />
          
        </Drawer>      
      </div>
  )
}
