import styles from "./create_location_modal.module.css";
import {useState, useEffect} from 'react';
import ApiConfig from "app/ApiConfig";

export default function CreateLocationForm({currentLocation}) {

  // // STATE
  console.log("CURRENT", currentLocation)
  // const [name, setName] = useState("");
  // const [description, setDescription] = useState("");
  // const [maxCapacity, setMaxCapacity] = useState(0)
  // const [images, setImages] = useState([])
  // const [videoUrls, setVideoUrls] = useState([]);
    // STATE
  const default_location = {
    name: "",
    description: "",
    maxCapacity: 0,
    images:[],
    videoUrls:[]
  }
  const [location, setLocation] = useState(default_location)   
  const [videoUrls, setVideoUrls] = useState([]); 
  const [formErrors, setFormErrors] = useState(null);
  //
  useEffect(()=>{
    if(currentLocation){
      setLocation(currentLocation)
      setVideoUrls(currentLocation?.videoUrls || [])
    } else {
      setLocation(default_location)
    }
  },[currentLocation])
  //
  //
  //
  const handleFileChange = (e) => {
    e.preventDefault();
    let files = e.target.files;
    // for(let i = 0; i <files.length; i++){
    //   console.log(files[i]);
    // }
    if(files.length){
      setLocation((old)=> ({...old, images: files}))
    }
  }
  const addVideoField = (e) =>{
    e.preventDefault();
    setVideoUrls((oldVal)=>{
      return [...oldVal, ""];
    })
  }
  const setVideoUrl = (e, idx)=>{
    setVideoUrls((oldVal)=>{
      oldVal[idx] = e.target.value;
      let newval = [...oldVal];
      setLocation((old)=>({...old, 'videoUrls': newval}))
      return newval
    })
  }

  //
  const handleInputChange = (e) => {
    setLocation((old)=>({...old, [e.target.id]:e.target.value}))
  }
  
  const handleSubmitLocation = async (e)=>{
    e.preventDefault();
    console.log(location)
    //
    let formData = new FormData();
    formData.append('name', location.name)
    formData.append('description', location.description)
    formData.append('maximum_capacity', location.maxCapacity)
    for(let i = 0; i <location.images.length; i++){
      //formData.append('image_urls', images[i])
      formData.append('files', location.images[i])
    }
    //formData.append('file', images[0])
    formData.append('videos_urls', videoUrls)
    //
    let locReq = null;
    if(!location.id) {
      locReq = await fetch(ApiConfig.locations, {
        method: "POST",
        body: formData
      })
    } else {
      locReq = await fetch(`${ApiConfig.locations}${location.id}`, {
        method: "PUT",
        body: formData
      })
    }
    const locRes = await locReq.json();
    const response = locRes.data;
    console.log(response)
    if(response.error) {
      setFormErrors(response.error)
    } else {
      
    }
  }

  return (
    <>
      <div className={``}>
      <section>
        <div className={styles.form_group}>
          <label htmlFor="">Name</label>
          <input type="text" name="" id="name" placeholder="Titre" value={location.name} onChange={(e)=> handleInputChange(e)}/>
        </div>

        <div className={styles.form_group}>
          <label htmlFor="">Description</label>
          <textarea name="" id="description" cols="30" rows="4"onChange={(e)=> handleInputChange(e)} value={location.description}></textarea>
        </div>

        <div className={styles.form_group}>
          <label htmlFor="">Maximum capacity</label>
          <input type="number" name="maxCapacity" id="" value={location.maxCapacity}
          onChange={(e)=> handleInputChange(e)}
          placeholder="Maximum capacity" />
        </div>
        <div className={styles.form_group}>
          <label htmlFor="file" className="sr-only">
            Choisir images
          </label>
          <input id="file" type="file" multiple onChange={handleFileChange} />
        </div>
        <div className={styles.form_group}>
          {videoUrls.map((v,index) => {
            return (
              <div key={index}>
                <label htmlFor="video_urls" className="sr-only">
                  Lien vidéo
                </label>
                <input id="video_urls" type="text" onChange={(e)=>setVideoUrl(e,index)} value={videoUrls[index]} />
              </div>
            )
          })}
          <button type="button" onClick={addVideoField}>Ajouter une vidéo</button>         
        </div>
        {formErrors ? <p className="text-error">{formErrors}</p> : ""}
        <div className={`${styles.form_inline_group} form_pull_right_cta`}>
          <button onClick={handleSubmitLocation} className={styles.submit_event}>Sauvegarder et continuer</button>
        </div>
      </section> 
      </div>
    </>
  )
}