import React from 'react'
import Footer from './components/Footer'
import Navbar from './components/Navbar'
import MetaTags from './components/MetaTags'

import styles from './events.module.css'
import { Button } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

export default function AboutUs() {

  const navigate = useNavigate();
  return (
    <>
    <MetaTags
      title="EventKati360" 
      description="Agence evenementiel en Afrique" 
      image='kati360.png'  
      url='https://eventkati360.com'
    />
    <Navbar />  
    <div className={`${styles.div15} page_section`}>
          <Button className={'get_back_btn'}  onClick={() => navigate(-1)}><FontAwesomeIcon icon={faCircleLeft}/>Retour à l'accueil</Button>
          <div className={styles.section_title}>A propos</div>{" "}
          <p>
          EventKATI360 est une plateforme panafricaine en libre-service spécialisée dans les expériences en direct. 
          Notre plateforme permet à chacun de créer, partager, trouver et participer à une vaste gamme d'événements 
          qui nourrissent les passions et enrichissent les expériences. Que ce soit du tourisme religieux, écologique, 
          des festivals de musique, des marathons, des conférences, des rassemblements communautaires, 
          des collectes de fonds, des concours de jeux ou des formations, 
          notre mission est de faciliter l'organisation de tous types d'événements.
          </p>
          <h3>Services Offerts</h3>
          <ul>
            <li>
              <strong>Création d'événements:</strong>
              <p>
              Les utilisateurs peuvent facilement créer leurs propres événements sur notre plateforme, en spécifiant les détails tels que la date, l'heure, le lieu, la catégorie et les informations pertinentes.
              </p>
            </li>
            <li>
              <strong>Partage et Promotion:</strong>
              <p>
              EventKATI360 permet aux organisateurs d'événements de partager leurs événements avec un large public grâce à des outils de promotion intégrés, y compris les réseaux sociaux et les campagnes marketing ciblées.
              </p>
            </li>
            <li>
              <strong>Recherche d'événements:</strong>
              <p>
              Les utilisateurs peuvent parcourir une variété d'événements disponibles sur la plateforme, en filtrant par catégorie, lieu, date, etc., pour trouver des événements qui correspondent à leurs intérêts et à leurs disponibilités.
              </p>
            </li>
            <li>
              <strong>Participation Facilitée:</strong>
              <p>
              Nous offrons des options de participation simples et sécurisées, notamment le paiement mobile, pour permettre aux participants de réserver leur place et de participer facilement à l'événement de leur choix.
              </p>
            </li>
            <li>
              <strong>Gestion des Billets Électroniques:</strong>
              <p>
              Les organisateurs peuvent gérer les billets électroniques pour leur événement, y compris la distribution, la validation à l'entrée et le compostage des billets électroniques après l'événement.
              </p>
            </li>
          </ul>

          <h3>Objectif</h3>
          <p> Notre objectif est de simplifier et de démocratiser l'organisation d'événements en Afrique, en offrant une plateforme conviviale et accessible à tous, des organisateurs d'événements aux participants, tout en garantissant une expérience fluide et enrichissante pour tous les utilisateurs.</p>
    </div>
    <Footer/>
  </>
  )
}
