import React from 'react'
import { Table, Badge } from 'react-bootstrap'

export default function BilleterieOrder({row}) {
  return (
    <Table hover>
        <thead>
        { row.billeterie.tickets.map((ticket)=>{
            return (<tr key={ticket.id}>
            <th><Badge bg="secondary"> {ticket.title}{`${ticket.total_seat ? ` x ${ticket.total_seat}` : ""}`} {`${ticket.total_price ? ` - ${ticket.total_price} FCFA` : ""} `}</Badge></th>
            {/* <th><Badge bg="warning">{parseInt(ticket.quantity) - parseInt(ticket.quantity_left)}</Badge></th> */}
            <th><Badge bg="success">{ticket.compost}</Badge></th>                            
          </tr>)
        }) }
        </thead>
    </Table>
  )
}
