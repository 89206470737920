import Footer from 'app/components/Footer'
import Navbar from 'app/components/Navbar'

import SuccessCheck from 'app/components/success-check/SuccessCheck';
import ApiConfig from 'app/ApiConfig';
import { useSearchParams, Link } from "react-router-dom";
import { useState , useEffect} from 'react';
import { Table , Alert} from 'antd';
import { getUser } from 'app/user_state';

export default function PaymentSuccess() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [ order, setOrder ] = useState([]);

  const user = getUser();
 

  useEffect(()=>{
    const fetchOrder = async () => {
      // console.log("SEARCH PARAMS IN FETCH ORDER");
      // console.log(searchParams)
      let filter = {
        'reference_id': searchParams.get('id')
      }
      //const response = await fetch(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,{
      const response = await fetch(`${ApiConfig.orders}?_child=true&_childRef[]=orderItems&_parent_field=order&filtre=${encodeURIComponent(JSON.stringify(filter))}`,{
        headers:{
          'Content-Type':'application/json'
        }
      });
      const orderRes = await response.json();

      // console.log("ORDER RESULT");
      console.log(orderRes);
      console.log(orderRes.data[0].children?.orderItems)
      setOrder(orderRes.data[0].children?.orderItems)
    }

    fetchOrder().catch(e=>console.log("ERROR",e));
  },[]);
  return (
    <>
    <Navbar />
      <div className="payment_feedback">
        <Alert type="success"
          message={(
          <>
            <strong>Paiement effectué avec succés</strong>
            <div className='check-holder'><SuccessCheck /></div>
          </>
        )}/>
          
        <Table 
          columns={[
            {
              title: "Ticket",
              render: (_,row)=> row.title
            },
            {
              title: "Quantité",
              render: (_,row) => row.total_seat ? row.total_seat : 0
            },
            {
              title: "Prix",
              render: (_,row) => row.total_price ? row.total_price : 0
            },
            {
              title: "Télécharger",
              render: (_,row) => <a href={`${row.pdf_link}`} download={`${row.title}-${row.id}`}>Telecharger le ticket</a>
            },
          ]}
          dataSource={order}
          pagination={false}
        />
        <br/>
        { user && <Alert message={<p>Retrouvez tous vos tickets en <Link to='/user/tickets'>cliquant ici</Link></p>} type="success" /> }
      </div>
    <Footer />
    </>
  )
}
