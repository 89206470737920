'use client';

import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faUser } from '@fortawesome/free-solid-svg-icons'

import { Dropdown } from 'react-bootstrap'
import { logout } from 'app/user_state';

import { useNavigate } from "react-router-dom";
import { Grid } from 'antd';
const { useBreakpoint } = Grid;

export default function UserMenu({user}) {
  const navigate = useNavigate();
  const screens = useBreakpoint();

  const handleLogout = (e) => {
    e.preventDefault();
    if(logout()){      
      window.location.reload()
    }
  }
  const goAddEvent = (e) => {
    e.preventDefault();
    navigate('/admin/events/generate');
  }
  const goUserEvent = (e) => {
    e.preventDefault();
    navigate('/user/events');
  }
  const goUserTicket = (e) => {
    e.preventDefault();
    navigate('/user/tickets');
  }
  const goUserMessage = (e) => {
    e.preventDefault();
    navigate('/user/messages');
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className={'eventkati_btn'} id="dropdown-basic">
      <FontAwesomeIcon icon={faUser} /> {screens.md && user.name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
      
        <Dropdown.Item onClick={goAddEvent}>Nouvel évènement</Dropdown.Item>              
        <Dropdown.Divider />
        <Dropdown.Item onClick={goUserMessage}>Message</Dropdown.Item>              
        <Dropdown.Item onClick={goUserTicket}>Tickets</Dropdown.Item>              
        <Dropdown.Item onClick={goUserEvent}>Evenements</Dropdown.Item>              
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>Deconnexion</Dropdown.Item>              
      </Dropdown.Menu>
    </Dropdown>
  )
}
