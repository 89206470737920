import ApiConfig from "app/ApiConfig";

export const fetchMessages = async (filter = {}, page, perPage) => {
  console.log("CURRENT PER PAGE", perPage)
  let start = (page - 1) * perPage ;
  let end = page * perPage;

  //const response = await fetch(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,{
  const response = await fetch(`${ApiConfig.messages}?_start=${start}&_end=${end}`,{
    headers:{
      'Content-Type':'application/json'
    }
  });
  const messages = await response.json();
  return messages;
};

export const postMessage = async (payload) => {
  const msgReq = await fetch(ApiConfig.messages, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers:{
      'Content-Type':'application/json'
    }
  })
  const msgRes = await msgReq.json();
  return msgRes;
}
