import styles from "./events.module.css";
import set from 'lodash/fp/set';
import get from 'lodash/fp/get';
import { useState, useEffect , Suspense} from 'react';
import { useRecoilState, useRecoilValue } from "recoil";

import Slider from "react-slick";

// import Image from 'next/image';

import Navbar from "app/components/Navbar";
import SingleEventCard from "app/components/single-event-card/single-event-card";
import OrganiseurHomeCard from "app/components/organiseur-home-card/organiseur-home-card";
import CategoryCard from 'app/components/category-home-card/category-card';

import ApiConfig from 'app/ApiConfig';
import Footer from "app/components/Footer";
import { Await, defer, useLoaderData } from "react-router-dom";
import { Input, Tooltip } from 'antd';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocationDot, faCircleInfo, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import MetaTags from "app/components/MetaTags";

import { listEvents } from "app/ressources/events";
import { eventFilterState, eventFilteredList, eventPaginationFilterState, eventSearchFilterState, default_event_filter } from "app/states/event_state";
import EventList from "app/components/events/EventList";
import SelectCountry from "app/components/countries/SelectCountry";
import HomeCarousel from "app/components/HomeCarousel";
import { useNavbarSize } from "./utils/useNavbarSize";
import OrganisateurSlider from "app/components/OrganisateurSlider";

export const accueilLoader = async () => {

  const catsRes = await fetch(ApiConfig.categories);
  const categories = catsRes.json();


  return defer({
    categories,
  })
}

const orgaSliderSettings = {
  infinite: true,
  autoplay: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export default function Accueil() {
  //
  const navbarSize = useNavbarSize();
  // search
  const [ eventFilter, setEventFilter ] = useRecoilState(eventFilterState);
  const [eventSearch, setEventSearch] = useRecoilState(eventSearchFilterState);
  const [eventPagination, setEventPagination] = useRecoilState(eventPaginationFilterState);
  const [localisationSearch, setLocalisationSearch] = useState("")
  const [dateSearch, setDateSearch] = useState("")

  useEffect(()=>{
    let current_filter = {...default_event_filter, country: eventFilter.country}
    setEventFilter(current_filter)
  },[])

  const handleEventSearchFilterChange = (e, target) => {
    let prop = e.target.name;
    let val = e.target.value;
    console.log(prop, " : ", val);
    let next_state = {...eventFilter, 'pagination': default_event_filter.pagination}
    setEventFilter(set(prop,val,next_state))
  }
  
  const handleEventCountryFilterChange = (e, target) => {
    let prop = e.target.name;
    let val = e.target.value;
    console.log(prop, " : ", val);
    let next_state = {...default_event_filter}
    setEventFilter(set(prop,val,next_state))
  }
  // loaders
  const { categories } = useLoaderData();
  //

    const [users, setUsers] = useState([]);
    useEffect(()=>{
      const fetchUsers = async () => {
        const res = await fetch(ApiConfig.users);
        const usersRes = await res.json();
        setUsers(usersRes.data);
      }
      fetchUsers().catch(e=>console.log(e));
    },[])
  // events
    const [events, setEvents] = useState([]);
    //
    const [canLoad, setCanLoad] = useState(true);
    const [eventPage, setEventPage] = useState(1);
    const [perPage, setPerPage] = useState(1);
    // initial load
    useEffect(()=>{
      setCanLoad(true);
      //setEventPage(1);
      if(eventPage == 1){

        const fetchEvents = async () => {
          const events = await listEvents({}, eventPage, perPage)
          setEvents(events.data)
        }
        fetchEvents().catch(e=>console.log(e));
      }
      // const fetchEvents = async () => {
      //   let url = ApiConfig.events;
      //    // default pagination        
      //    url += `?_start=${0}&_end=${perPage}`
        
      //   // filtres
      //   let filter = {};
      //   if(localisationSearch){
      //     filter['location_data'] = {};
      //     filter['location_data']['locationMeta'] = localisationSearch
      //   }
      //   if(dateSearch){
      //     filter['date_time'] = {};
      //     filter['date_time']['date_start'] = dateSearch
      //   }
      //   if( Object.keys(filter).length){
      //     let filter_url = encodeURIComponent(JSON.stringify(filter));
      //     url = `${url}&filtre=${filter_url}`
      //   }          
      //   // request
      //   const res = await fetch(url);
      //   const eventsRes = await res.json();
      //   setEvents(eventsRes.data);
      // }
      // fetchEvents().catch(e=>console.log(e));
    },[eventPage, perPage])
    //pagination load
    useEffect(()=>{
      // if(eventPage > 1 && canLoad) {
      //   const fetchEvents = async () => {
      //     let url = ApiConfig.events;
          
      //     // pagination
      //     let start = (eventPage - 1) * perPage ;
      //     let end = eventPage * perPage;
      //     url += `?_start=${start}&_end=${end}`

      //     // filtres
      //     let filter = {};
      //     if(localisationSearch){
      //       filter['location_data'] = {};
      //       filter['location_data']['locationMeta'] = localisationSearch
      //     }
      //     if(dateSearch){
      //       filter['date_time'] = {};
      //       filter['date_time']['date_start'] = dateSearch
      //     }
      //     if( Object.keys(filter).length){
      //       let filter_url = encodeURIComponent(JSON.stringify(filter));
      //       url = `${url}&filtre=${filter_url}`
      //     }          
      //     // request
      //     const res = await fetch(url);
      //     const eventsRes = await res.json();
      //     if(eventsRes.data.length == 0 ) { // no more items to load
      //       setCanLoad(false)
      //     } else { // received items from request
      //       setEvents((old)=> {
      //         //console.log("EVENT :")
      //         let current = [...old];
      //         for(let i = 0; i< eventsRes.data.length; i++) {
      //           let event = eventsRes.data[i];
      //           // do not add already existing event
      //           if(!current.some((elem)=> event.id == elem.id)) {
      //             current.push(event)
      //           }
      //         }
      //         //console.log(current)  ;
      //         return current
      //       });
      //     }
      //   }
      //   fetchEvents().catch(e=>console.log(e));
      // }
    if(eventPage > 1 && canLoad) {
      const fetchEvents = async () => {
        const eventsRes = await listEvents({}, eventPage, perPage);
        //
        if(eventsRes.data.length == 0 ) { //no more items to load
          console.log("no more items")
          setCanLoad(false)
        } else { // received items from request
          console.log("some new items")
          setEvents((old)=> {
            //console.log("EVENT :")
            let current = [...old];
            for(let i = 0; i< eventsRes.data.length; i++) {
              let event = eventsRes.data[i];
              // do not add already existing event
              if(!current.some((elem)=> event.id == elem.id)) {
                current.push(event)
              }
            }         
            return current
          });
        }        
      }
      fetchEvents().catch(e=>console.log(e));
    }
    },[eventPage, perPage])
  // categories
  // const [categories, setCategories] = useState([]);
  // useEffect(()=>{
  //   const fetchCategories = async () => {
  //     const res = await fetch(ApiConfig.categories);
  //     const catsRes = await res.json();
  //     setCategories(catsRes.data);
  //   }
  //   fetchCategories().catch(e=>console.log(e));
  // },[])

    const loadMoreEvent = (e)=>{
      e.preventDefault();
      let ns = {...eventFilter}
     
      setEventFilter( set('pagination.page', (eventFilter.pagination.page + 1), ns) );
    }


  return (
    <>
        <MetaTags 
          title="EventKati360" 
          description="Agence evenementiel en Afrique" 
          image='kati360.png'  
          url='https://eventkati360.com'
        />
        <Navbar />       
        <div style={{paddingTop: navbarSize?.height}}></div>        
        <HomeCarousel/>          
        <SelectCountry display="list"/>
        <div className={`${styles.div15} page_section`}>
          <div className={styles.section_title}>Nos catégories</div>{" "}
          <div className={styles.home_categories_list}>
            <Suspense fallback={<p>Chargegement ...</p>}>
              <Await resolve={categories}>
                {(categories)=>{
                    return categories.data.map((cat)=> {
                      return <CategoryCard category={cat} key={cat.id} />
                    })
                }}
              </Await>
            </Suspense>
          </div>
          <div className={styles.section_title}>Évènements</div>{" "} 
          <div className={`${styles.search_section}`}>   
            <span style={{color: 'gray'}}>Filter les évènements</span>
            <div className={`form_inline_group filter_box`}>            
              <p>
              <Input
                placeholder="par lieu"
                value={eventFilter['search']['location_data']['locationMeta']}
                name="search.location_data.locationMeta"
                //onChange={e=>setLocalisationSearch(e.target.value)}
                onChange={handleEventSearchFilterChange}
                prefix={<FontAwesomeIcon icon={faMapLocationDot}  style={{color: 'var(--green-kati)'}}/>}
                suffix={
                  <Tooltip title="Taper un lieu (ville, quartier...)">
                    <FontAwesomeIcon icon={faCircleInfo} style={{color: 'lightgray'}} />
                  </Tooltip>
                }
              />
              </p>
              <p>
              <Input
                placeholder="par date"
                //value={get('search.date_time.date_start',eventFilter)} 
                value={eventFilter['search']['date_time']['date_start']}
                name="search.date_time.date_start"
                type="date"
                onChange={handleEventSearchFilterChange}
                prefix={<FontAwesomeIcon icon={faCalendarDays} style={{color: 'var(--green-kati)'}} />}
                suffix={
                  <Tooltip title="Rechercher par date">
                    <FontAwesomeIcon icon={faCircleInfo} style={{color: 'lightgray'}} />
                  </Tooltip>
                }
              />
              </p>
            </div>
          </div>
          <Suspense fallback={<p>Chargement....</p>}>
            <EventList/>
          </Suspense>
         <div style={{marginBottom: '50px'}}>
            <div className={styles.section_title}>Organisateurs à suivre</div>{" "}           
            <OrganisateurSlider/>            
          </div>         
        </div>{" "}            
    <Footer />
    </>
)};