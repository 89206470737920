'use client';

import 'app/admin.css'

import { useEffect, useState } from 'react'
import { useRecoilState, useResetRecoilState } from 'recoil';
import { createEventSteps, createEventForm } from 'app/states/event_state';

import NavbarAdmin from 'app/components/NavbarAdmin';
import SidebarAdmin from 'app/components/SidebarAdmin';
import CreateEvent1 from './CreateEvent1';
import CreateEvent2 from './CreateEvent2';
import CreateEvent3 from './CreateEvent3';
import CreateEvent4 from './CreateEvent4';

import { getUser } from 'app/user_state';
import { useNavigate, useLocation } from 'react-router-dom';

const steps = [
    {
      id: '1',
      name: 'Informations de bases',
      fields: ['firstName', 'lastName', 'email']
    },
    {
      id: '2',
      name: 'Details',
      fields: ['country', 'state', 'city', 'street', 'zip']
    },
    {
      id: '3',
      name: 'Billets',
      fields: ['country', 'state', 'city', 'street', 'zip']
    },
    { id: '4', name: 'Publier' }
  ]

export default function EventGenerate() {
  
  
  const [currentStep, setCurrentStep] = useRecoilState(createEventSteps);
  const [eventForm, setEventForm] = useRecoilState(createEventForm);
  const resetEvent = useResetRecoilState(createEventForm);
  
  const navigate = useNavigate();
  const location = useLocation();

  let user = null;
  useEffect(()=>{
    user = getUser() ;
    // reset event form
    resetEvent();
  },[]);

  //setup
  useEffect(() => {
    //document.getElementById("contentadmincontainerscroller").scroll(0,0)    
    console.log("should scroll top")
  }, [currentStep]);
  
  const next = (e) => {  
   // e.preventDefault();
    document.getElementById("contentadmincontainerscroller").scroll(0,0)  
    if (currentStep < steps.length - 1) {
      setCurrentStep(step => step + 1)
    }
  }
  const prev = (e) => {  
    e.preventDefault();
    if (currentStep > 0 ) {
      setCurrentStep(step => step - 1)
    }
  }

  const handleStepBtn = (e, step)=>{
    e.preventDefault();
    setCurrentStep(step);
  }

  useEffect(()=>{
    if(!user){
       navigate('/login')
    }
  },[])

  return (
    <>
    {location.pathname == '/admin/events/generate' && (
      <div className="create_event_sidebar_menu">       
        <button className={`create_event_step_btn ${currentStep == 0 ? 'active': ''}`} onClick={e=>handleStepBtn(e,0)}>
          <span className="create_event_steps_badge">1</span> <span className="_sm"> Informations de bases</span>
        </button>
        <button className={`create_event_step_btn ${currentStep == 1 ? 'active': ''}`} onClick={e=>handleStepBtn(e,1)}>
          <span className="create_event_steps_badge">2</span><span className="_sm">Details</span>
          </button>
        <button className={`create_event_step_btn ${currentStep == 2 ? 'active': ''}`} onClick={e=>handleStepBtn(e,2)}>
          <span className="create_event_steps_badge">3</span><span className="_sm">Billets</span>
        </button>
        <button className={`create_event_step_btn ${currentStep == 3 ? 'active': ''}`} onClick={e=>handleStepBtn(e,3)}>
          <span className="create_event_steps_badge">4</span><span className="_sm">Publier</span>
        </button>             
      </div>
      )}
    <div id="contentadmincontainerscroller">  
      {currentStep === 0 && (
        <CreateEvent1 next={next}></CreateEvent1>
      )}

      {currentStep === 1 && (
        <CreateEvent2 next={next} prev={prev}></CreateEvent2>
      )}

      {currentStep === 2 && (
        <CreateEvent3 next={next} prev={prev} />
      )}
      {currentStep === 3 && (
        <CreateEvent4 next={next} prev={prev} />
      )}

      
      </div>
   </>
  )
}
