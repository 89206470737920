import React, {useState, useEffect} from 'react'
import { Table } from 'antd'
import { fetchMessages } from 'app/ressources/messages';
import moment from 'moment';
import { getUser } from 'app/user_state';

export default function MessageList() {

  const user = getUser();

  const columns = [
    {       
      title: "Nom Prenom",
      render: (_,row) => {          
        return `${row.lname} ${row.fname}`
      }
    }, 
    {
      title: 'Date',
      render: (_,row) => `${moment(row.createdAt).format('DD/MM/YY - HH:mm')}`,
    }
    , 
    {
      title: 'Action',
      render: (_,row) => (
        <p>Action</p>
      // <Dropdown>
      //   <Dropdown.Toggle variant="secondary" id="dropdown-basic">
      //     Paramètres
      //   </Dropdown.Toggle>

      //   <Dropdown.Menu>
      //     <Dropdown.Item onClick={e=>openUpdateEventModal(e, row)}>Modifier</Dropdown.Item>
      //     <Dropdown.Item onClick={e=>openDeleteEntityModal(e, {...row, title: row['name']})}>Supprimer</Dropdown.Item>
      //   </Dropdown.Menu>
      // </Dropdown>
      ),
    }
  ]

    // DATATABLE
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);

  useEffect(()=>{
    setLoading(true);
    
    const messages =  async () => {
      let msg = await fetchMessages({planner_id: user?.uid},page, perPage);
      setData(msg.data)
      setTotalRows(msg.total);
      setLoading(false);
    }

    messages().catch(e=>console.log(e));
  },[page, perPage])
  return (
    <>
    <Table
          rowKey={'id'}
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            pageSize: perPage,
            total: totalRows,
            onChange:(page)=>{
              setPage(page)
            }
          }}
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: (record) => ({
              //disabled: record.name === 'Disabled User',
              // Column configuration not to be checked
              name: record.id,
            }),
          }}
        /> 
    </>
  )
}
