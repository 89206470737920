'use client';

import { useState, useEffect } from 'react';
import 'app/admin.css'

import ApiConfig from 'app/ApiConfig';
import AppConfig from 'app/AppConfig';

import NavbarAdmin from 'app/components/NavbarAdmin';
import SidebarAdmin from 'app/components/SidebarAdmin';
import QrCodeModal from 'app/components/qrcode-modal/QrCodeModal';
import BilleterieOrder from 'app/components/events/BilleterieOrder';

import { Badge } from 'react-bootstrap';

import { getUser } from 'app/user_state';
import { useNavigate} from 'react-router-dom';
import { Table } from 'antd';


export default function UserTickets () {
  // SEARCH
  const [nameSearch, setNameSearch] = useState("");
  const [dateStartSearch, setDateStartSearch] = useState("");
  const [dateEndSearch, setDateEndSearch] = useState("");
  const [columns, setColumns] = useState([]);

  let user = null;
  useEffect(()=>{
    user =  getUser();
  },[]);

  const navigate = useNavigate()

  useEffect(()=>{
    if(!user){
      navigate('/login');
    } else {
      //console.log("user logged in")
    }

  },[])

	// compostage
	const [showQrCodeModal, setShowQrCodeModal] = useState(false);

	const openComposteModal = (e) => {
		e.preventDefault();
		setShowQrCodeModal(true);
	}

	const onHide = () => {
		setShowQrCodeModal(false);
	}
  
  // get column
  useEffect(()=>{
    const columns_builder = [
      // {
      //   name: ( 
      //     <div className={styles.form_group}>
      //       <strong>Nom de l'acheteur</strong>
      //       <input type="text" value={nameSearch} onChange={e=>setNameSearch(e.target.value)} placeholder='Rechercher par nom' />
      //     </div>
      //   ),
      //   selector: row => { 
      //     return row.client_name
      //   }
      // },  
      {
        title: "Nom de l'acheteur",
        render: (_,row)=>{
          return row.client_name
        }
      },    
      {
        title: 'Telephone',
        render: (_,row) => row.client_phone,
      },
      {
        title: 'Total',
        render: (_,row) => row.total_price,
      },
      
      {
        title: (<>Tickets <Badge bg="success">Composté</Badge></>),
        render: (_,row) =>{
               return <BilleterieOrder row={row}/>
        }
      },
      {
        title: 'Paiement',
        render: (_,row) => {
          if(row.payment_method == "wave")
            return <img src ="/images/wave.png" style={{width: "50px", height: "auto"}}/>
          if(row.payment_method == "om")
            return <img src ="/images/om.png" style={{width: "50px", height: "auto"}}/>
          if(row.payment_method == "visa")
            return <img src ="/images/visa.svg" style={{width: "50px", height: "auto"}}/>

          return row.payment_method
          }
      },
    ];

    setColumns(columns_builder);
  },[])

// LISTEN SEARCH
  // useEffect(()=>{
  //   let filters = {};
  //   if(nameSearch)
  //     filters['title'] = nameSearch

  //   if(dateStartSearch){
  //     filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
  //     filters['date_time']['date_start'] = dateStartSearch
  //   }

  //   if(dateEndSearch){
  //     filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
  //     filters['date_time']['date_end'] = dateEndSearch
  //   }
  //   console.log('FINALE FILTER')
  //   console.log(filters);
  //   console.log(encodeURI(JSON.stringify(filters)))
  // },[nameSearch, dateStartSearch, dateEndSearch])


  // DATATABLE
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);

	

	const fetchOrders = async page => {
		setLoading(true);
    // console.log("CURRENT PER PAGE", perPage)
    let start = (page - 1) * perPage ;
    let end = page * perPage;

    let user_filter = {}
    if(user) {
      user_filter = {
        "owner_id": user.uid
      };
    }

		//const response = await fetch(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,{
		const response = await fetch(`${ApiConfig.orders}?_start=${start}&_end=${end}&_child=true&_childRef[]=orderItems&_childRef[]=keper&_parent_field=order&filtre=${ encodeURIComponent(JSON.stringify(user_filter)) }`,{
      headers:{
        'Content-Type':'application/json'
      }
    });
    let orders = await response.json();
    setTotalRows(orders.total);

    orders = AppConfig.appendCompostToOrders(orders)
		setData(orders);
		setLoading(false);
	};

	const handlePageChange = page => {
		fetchOrders(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
    //fetchUsers(page);
	};

  const handleChange = ({ selectedRows }) => {
    console.log(selectedRows);
  };

	useEffect(() => {
		fetchOrders(page); // fetch page 1 of users
		
	}, [perPage, page]);

	return (
    <>
		
        <Table 
          columns={columns}
          data={data}
          pagination={{
            pageSize: perPage,
            total: totalRows,
            onChange:(page)=>setPage(page)
          }}
          loading={loading}
        />
			<QrCodeModal show={showQrCodeModal} onHide={onHide}/>
    </>
	);
};
