import React from 'react'
import { Outlet } from 'react-router-dom'
import SidebarAdmin from './SidebarAdmin'
import NavbarAdmin from './NavbarAdmin'

export default function AdminContainer() {
  return (
    <>
    <section className="adminContainer">
      <div className="sidebarAdminContainer">
        <SidebarAdmin currentPage="event_board"/>
      </div>
      <div className="contentAdminContainer">
        <NavbarAdmin />
        <Outlet/>
      </div>
    </section>
    </>
  )
}
