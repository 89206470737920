'use client';

import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getUser } from 'app/user_state';

import UserMenu from './UserMenu';
import { Grid } from 'antd';
const { useBreakpoint } = Grid;


export default function NavbarAdmin() {
    
  const [user, setUser ] = useState(getUser());
  
  const screens = useBreakpoint();

  return (
    <>
      { screens.md && (
        <div className="admin_navbar">
          <aside>
          {/* <Link to="/">
            <img 
              src="/kati360.jpg"
              id="logo"
            />
          </Link> */}
          </aside>
          <nav id="menu">       
            {(user && <UserMenu user={user}/>) || "Aucun user"}
          </nav>
        </div>
      )}
    </>
  )
}
