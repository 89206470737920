import React, {useEffect, useState } from 'react'
import { useRecoilValue, useRecoilState } from 'recoil';
import { eventFilteredList, eventFilterState } from 'app/states/event_state';
import SingleEventCard from '../single-event-card/single-event-card';
import set from 'lodash/fp/set'

import styles from "../../events.module.css";


export default function EventList() {
  const [ events, setEvents ] = useState([]);
  const [eventFilter, setEventFilter] = useRecoilState(eventFilterState)
  const eventFilteredResult = useRecoilValue(eventFilteredList);

  useEffect(()=>{
    console.log('event filter', eventFilter)
    console.log('event list' , eventFilteredResult)
    const { events: eventsRes, filter } = eventFilteredResult
    if(filter.pagination.page > 1) {
      setEvents((old)=>[...old, ...eventsRes.data])
    } else {
      setEvents(eventsRes.data)
    }
    //
    eventsRes.data.length == 0 ? setCanLoad(false) : setCanLoad(true)
  },[eventFilter])

  // 
  const [canLoad, setCanLoad] = useState(true);
  const loadMoreEvent = (e)=>{
    e.preventDefault();
    let ns = {...eventFilter}
    setEventFilter( set('pagination.page', (eventFilter.pagination.page + 1), ns) );
  }


  return (
    <>
    <div className={styles.events_list}>
        {events.map((event)=>{
          return (
          <SingleEventCard event={event} key={event.id}/>                                
          )
        })}                                 
    </div>{" "}
    <div className="load_more_row" style={{textAlign: "center"}}><button className="btn load_more_btn" onClick={loadMoreEvent} disabled={!canLoad}>Voir plus d'évènements</button></div>
           
    </>
  )
}
