import { atom, selector, useSetRecoilState } from 'recoil';


export const setUser = (user) => {
  window.localStorage.setItem('ek360_current_user', JSON.stringify(user));
}

export const getUser = () => {

  return JSON.parse(window.localStorage.getItem('ek360_current_user')) || null;
}

export const logout = () => {
  window.localStorage.removeItem('ek360_current_user')
  return true
}