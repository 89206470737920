import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone,faMobileScreenButton, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import dayjs from 'dayjs';


export default function Footer() {
  return (
    <footer id="footer">
      <section id="footer_top" class="page_section">      
        <div id="footer_top_presentation">
          <h3>EVENTKATI360</h3>
          <p>
          EventKATI360 est là pour transformer la manière dont les événements sont organisés et vécus en Afrique, en offrant une plateforme inclusive et innovante pour explorer, découvrir et participer à une multitude d'expériences en direct.
          </p>
        </div>
        {/* <div>
          <h3>Services</h3>
          <ul>
            <li>Notre application</li>
            <li>Nos galeries</li>
            <li>Nos articles</li>
            <li>Nos vidéos</li>
          </ul>
        </div> */}
        <div>
          <h3>Nous contacter</h3>
          <ul>
            <li><FontAwesomeIcon icon={faPhone} className="footer_icon" /> 33 848 29 71</li>
            <li><FontAwesomeIcon icon={faMobileScreenButton}  className="footer_icon" /> 77 190 46 45</li>            
            <li><FontAwesomeIcon icon={faEnvelope}  className="footer_icon" /> contact@kati360.digital</li>
          </ul>
        </div>
        <div>
          <h3>Ou nous trouver ?</h3>
          <p> <FontAwesomeIcon icon={faLocationDot}  className="footer_icon"/> Dakar, Sénégal</p>
          <br />
          <h3>Nous suivre</h3>
          <p>
           <span className="socialMediaIcon"><FontAwesomeIcon icon={faFacebookF}  className="footer_icon"/></span>
           <span className="socialMediaIcon"><FontAwesomeIcon icon={faTwitter}  className="footer_icon"/></span>
           <span className="socialMediaIcon"><FontAwesomeIcon icon={faInstagram}  className="footer_icon"/></span>
           <span className="socialMediaIcon"><FontAwesomeIcon icon={faLinkedinIn}  className="footer_icon"/></span>
          </p>
        </div>
      </section>
      <hr />
      <section id="footer_bottom" class="page_section">
        <p>
          <img className="logo" src="/kati360.png" alt="" />
          Copyrights EventKati360. All rights reserved {dayjs().format('YYYY') }
        </p>
        <p className="footer_payment_methods">
          <img src="/images/visa.svg" alt=""  />
          <img src="/images/om.png" alt="" className="payment_method_icon"/>
          <img src="/images/wave.png" alt="" className="payment_method_icon"/>
        </p>
      </section>
    </footer>
  )
}
