"use client";

import styles from "./create_event.module.css";
import {useState, useEffect} from 'react';
import ApiConfig from "app/ApiConfig";
import GoogleMapReact from 'google-map-react';
import { useRecoilState } from 'recoil';

import { ErrorBoundary } from "react-error-boundary";
import dayjs from 'dayjs';
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { countries } from "app/components/countries/countries";

import { createEventForm } from 'app/states/event_state';
import TransportationFormModal from "app/events/transportations/TransformationFormModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Select, DatePicker, TimePicker } from "antd";
import { getUser } from 'app/user_state';
import { Accordion } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import * as yup from 'yup';
import LocationFormModal from "../locations/LocationFormModal";


export default function CreateEvent1({next, display}) {
  //setup
  const [eventForm, setEventForm ] = useRecoilState(createEventForm);
  const [category, setCategory] = useState('');

  const user = getUser();
  useEffect(()=> {
    setEventForm((old)=> {
      return {...old, 'owner_id': user?.uid, 'owner_name': user?.name}
    })
  },[])

  const information_fields = ['title','category_id'];
  const transport_fields = [''];  
  const location_fields = [''];
  const datetime_fields = [''];
  //types
  const [types, setTypes] = useState([]);
  useEffect(()=>{
    const fetchTypes = async () => {
      const res = await fetch(ApiConfig.types);
      const typesRes = await res.json()
      setTypes(typesRes.data);
    }
    fetchTypes().catch(e=>console.log(e));
  },[]);
  // categories
  const [categories, setCategories] = useState([]);
  useEffect(()=>{
    const fetchCategories = async () => {
      const res = await fetch(ApiConfig.categories);
      const catsRes = await res.json();
      setCategories(catsRes.data);
    }
    fetchCategories().catch(e=>console.log(e));
  },[])
  //subcategories
  const [subCategories, setSubCategories] = useState([]);
  const handleCategorieChange = async (e) => {
    const cat_id = e;
    //console.log("CATEGORIE CHANGED ", cat_id);
    if(cat_id){
      setCategory(cat_id)
      setEventForm((old)=>{
        return {...old, 'category_id':cat_id}
      })
     
    } else {
      setCategory("");
      
    }
  }
  useEffect(()=>{
    console.log("CAT EFFECT ", category)
    if(category) {
      const getSub = async () => {
        const subReq = await fetch(`${ApiConfig.sub_categories}${category}`);
        const sub = await subReq.json();
        setSubCategories(sub.data);
      }
      getSub().catch(e=>console.log("get sub error", e))
    } else {
      setSubCategories([]);
    }
    console.log("subCat",subCategories)
  },[category])
  // transports
  const [transportations, setTransportations] = useState([]);
  const [showCreateTransportationModal, setShowCreateTransportationModal] = useState(false);
  useEffect(()=>{
    const fetchTransportations = async () => {
      const response = await fetch(`${ApiConfig.transportations}`,{
        headers:{
          'Content-Type':'application/json'
        }
      });
      const transRes = await response.json();
      setTransportations(transRes.data);
    }
    fetchTransportations().catch(e=>console.log(e));
  },[])
  const handleTransportChange = (e) => {
    // e.preventDefault();
    // let target = e.target.value;
    let target = e;
    console.log("target",e)
    if(target == "autre_transport") {
      setShowCreateTransportationModal(true);
    } else {
      setEventForm((old)=>{
        return {...old, transportation_data:{...old['transportation_data'], transport_id: target}}
      })
    }
  }

  // Locations
  const [locations, setLocations] = useState([]);
  const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);
  useEffect(()=>{
    const fetchLocations = async () => {
      const response = await fetch(`${ApiConfig.locations}`,{
        headers:{
          'Content-Type':'application/json'
        }
      });
      const locRes = await response.json();
      setLocations(locRes.data);
    }
    fetchLocations().catch(e=>console.log(e));
  },[])
  const handleLocationChange = (e) => {
    // e.preventDefault();
    // let target = e.target.value;
    let target = e;
    if(target == 'autre_lieu') {
      setShowCreateLocationModal(true);
    }else {
      setEventForm((old)=>{
        return {...old, location_data:{...old['location_data'], location_id: target}}
      })
    }
  }
  const handleSelectLocation = (e) => {
    e.preventDefault();
    let target = e.target.getAttribute('id');
    setEventForm((old)=>{
      return {...old, location_data:{...old['location_data'], location: target}}
    })
  }
  const handleCountryChange = (val) => {
    setEventForm((old)=>{
      return {...old, location_data:{...old['location_data'], country: val}}
    })
  }
  // MAP 
  const [ showMap, setShowMap ] = useState(false);
  useEffect(()=>{
    const timer = setTimeout(()=>{
      setShowMap(true)
    },3000);
    return () => {
      clearTimeout(timer);
    }
  },[])
  //
  const _defaultProps = {
    center: {
      lat: 14.7645042,
      lng: -17.3660286
    },
    zoom: 11
  };
  const [defaultProps, setDefaultProps ] = useState(_defaultProps)
  const AnyReactComponent = ({ text }) => (
    <div className="map_marker"></div>
  );
  const [mapCenter, setMapCenter] = useState(defaultProps.center);
  const handleMapDrag = (map) => { 
    setMapCenter({lat: map.center.lat(), lng: map.center.lng()})
    setDefaultProps((old)=>({...old, 'zoom': 14, 'center': { lat: map.center.lat(), lng: map.center.lng()}}))
    setEventForm((old)=>{
      return {...old, 'location_data': {...old['location_data'], latitude: map.center.lat() , longitude:map.center.lng()}}
    })
  }
  // FORM FILLING
  const handleSelectTiming = (e) => {
    e.preventDefault();
    let target = e.target.getAttribute('id');
    setEventForm((old)=>{
      return {...old, date_time:{...old['date_time'],frequency: target}}
    })
  }
  //
  const handleInputEventChange = (e, field, subfield = null, val = null) => {
    let value = e?.target?.value || val
    if(field == "tags"){
      value = value.split(",");
    }
    setEventForm((old,curr)=> {
      if(subfield) {
        let obj = {
          [field]:{
            ...old[field],
            [subfield]: value
          }
        };
        return {...old, ...obj}
      } else {      
        return {...old , [field]: value}
      }
    })
  }
  // ACCORDEON
  const [activeTabs, setActiveTabs] = useState(["0","1","2","3"])
  const selectTab = (idx)=> {    
    activeTabs[0] == idx ? setActiveTabs([]) : setActiveTabs([idx])
  }
  //
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };

  // FORM VALID AND SUBMIT

  const validateStep1 = yup.object({
    title: yup.string().required("Veuillez renseigner un titre"),
    category_id: yup.string().required('Veuillez selectionner une categorie'),
    subcategory_id: yup.string().required('Veuillez selectionner une sous-categorie'),
    tags: yup.array().min(1,'Veuillez choisir au moins 1 mot clé'),
    //
    // location_data: {
    //   location: "Lieu", 
    //   locationMeta: "",
    //   location_id: "",
    //   latitude: null,
    //   longitude: null
    // },
    transportation_data: yup.object().shape({
        transport_id: yup.string().required('Veuillez choisir un transporteur')
    }),
    date_time: yup.object().shape({
        frequency: yup.string().required("Veuillez choisir la frequence"), 
        date_start: yup.string().required("Veuillez choisir la date de début"), 
        time_start: yup.string().required("Veuillez choisir l'heure de début"), 
        date_end: yup.string().required("Veuillez choisir la date de fin"),
        time_end: yup.string().required("Veuillez choisir l'heure de fin"),
    })
    
  })

  const [formErrors, setFormErrors ] = useState({});

  const formHasErrors = (formErr, fields) => {
    return Object.keys(formErr).filter((key)=>(fields.includes(key))).length
  }

  const handleEventCreate = async (e) => {
    e.preventDefault();

    try{
      await validateStep1.validate(eventForm, {abortEarly: false});
      next();
    } catch(errors){
      console.log(eventForm);
      console.log("ERRORS");
      let err = errors.inner.reduce((state, current)=> {
        state[current['path']] = current.message
        return state
      },{})
      console.log(err);
      setFormErrors(err);
      setActiveTabs([]);
    }
  }
  const [value, setValue] = useState(null);
  // useEffect(()=>{
  //   console.log(value);
  // },[value])
  return (
    <>
    <div className={`${display != 'update_modal' ? styles.form_section : ""}`}>
    <Accordion activeKey={activeTabs} flush onSelect={selectTab}>
      <Accordion.Item eventKey="0">
          <Accordion.Header>
            <h2 className={`${styles.info_title} ${styles.title_icons}`}>
              Informations de base
              {formHasErrors(formErrors, information_fields) ? <p className="text-error">{formHasErrors(formErrors, information_fields)}Erreurs</p> : ""}
            </h2></Accordion.Header>
          <Accordion.Body>
        <p>Nommez votre événement et dites aux participants pourquoi ils devraient venir. Ajoutez des détails qui mettent en évidence ce qui le rend unique.</p>
        <div className={styles.form_group}>
          <label htmlFor="">Titre de l'evenement</label>
          <input type="text" name="" id="" placeholder="Titre" value={eventForm.title} onChange={(e)=> handleInputEventChange(e, "title")}/>
          {formErrors['title'] && <span style={{display:'block'}} className="text-error">{formErrors['title']}</span>}
        </div>

        {/* <div className={styles.form_group}>
          <label htmlFor="">Description de l'evenement</label>
          <textarea name="" id="" cols="30" rows="4"onChange={(e)=> handleInputChange(e, setDescription)}>{description}</textarea>
        </div> */}

        {/* <div className={styles.form_group}>
          <label htmlFor="">organisateur/ organisatrice</label>
          <input type="text" name="" id="" value={eventForm.owner}
          onChange={(e)=> handleInputEventChange(e, "owner_id")}
          placeholder="dire aux participants qui organise cet événement" />
        </div> */}

        <div className={styles.form_group}>
          <label htmlFor="">Tenez-moi au courant de plus d'événements et de nouvelles de cet organisateur d'événements.</label>
          <div className={styles.form_inline_group}>
            <Select 
              defaultValue={eventForm.category_id || "0"}
              style={{width: '50%'}}
              options={[{value:"0",label:"Categorie"},...categories.map((cat)=> ({value: cat.id, label: cat.name}))]}
              onChange={handleCategorieChange}                
            /> 
            {formErrors['category_id'] && <span style={{display:'block'}} className="text-error">{formErrors['category_id']}</span>}          
            <Select 
              defaultValue={eventForm.subcategory_id || "0"}
              style={{width: '50%'}}
              options={[{value:"0",label:"Sous-categorie"},...subCategories.map((cat)=> ({value: cat.id, label: cat.name}))]}
              onChange={(e)=> handleInputEventChange(e, "subcategory_id", null, e)}           
            />           
            {formErrors['subcategory_id'] && <span style={{display:'block'}} className="text-error">{formErrors['subcategory_id']}</span>} 
          </div>
        </div>
        <div>
          <strong>Mots clés</strong>
        </div>
        <div className={styles.form_group}>
          <label htmlFor="">Améliorez la visibilité de votre événement en ajoutant des balises pertinentes au sujet.</label>
          <div className={`btn_pull_right ${styles.form_inline_group}`}>
            <input className={styles.input_left} type="text" name="" id="" 
            placeholder="Separer les mots clés par une virgule" 
            value={eventForm.tags}
            onChange={e=>handleInputEventChange(e,"tags")}/>
            {/* <button className={styles.btn_green}>Ajouter</button> */}
            {formErrors['tags'] && <span style={{display:'block'}} className="text-error">{formErrors['tags']}</span>}
          </div>
        </div> 
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
          <Accordion.Header><h2 className={`${styles.location_title} ${styles.title_icons}`}>Transport</h2></Accordion.Header>
          <Accordion.Body>
         <p>Aidez les gens de la région à découvrir votre événement et faites savoir aux participants où se présenter.</p>
        <div className={styles.form_group}>
          <label htmlFor="">Transporteur</label>
          <Select 
              defaultValue={eventForm.transportation_data?.transport_id|| "0"}
              style={{width: '50%'}}
              options={[{value:"0",label:"Transport"},...transportations.map((transport)=> ({value: transport.id, label: transport.name})), {value:"autre_transport",label:"Autres"}]}
              onChange={handleTransportChange}           
            />      
            {formErrors['transportation_data.transport_id'] && <span style={{display:'block'}} className="text-error">{formErrors['transportation_data.transport_id']}</span>} 
            {/* <select name="" id="" onChange={handleTransportChange}>
              <option value="">Transport</option>
              {transportations.map((transport)=> {
                if(eventForm.transportation_data?.transport_id == transport.id){
                  return <option value={transport.id} key={transport.id} selected >{transport.name}</option>                
                } else {
                  return <option value={transport.id} key={transport.id} >{transport.name}</option>                
                }
              })}
            </select> */}
        </div>
          </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
          <Accordion.Header><h2 className={`${styles.location_title} ${styles.title_icons}`}>Emplacement</h2></Accordion.Header>
          <Accordion.Body>
        <p>Aidez les gens de la région à découvrir votre événement et faites savoir aux participants où se présenter.</p>
        <div className={styles.form_inline_group}>       
          <label htmlFor="">Pays</label>   
          <Select
            value={eventForm.location_data.country}
            style={{
              width: '100%',
            }}
            onChange={handleCountryChange }
            options={[        
              ...countries.map((c)=>({value: c.name, label: <><span><img className="country_icon_sm" src={c.icon}/>{c.name}</span></> }))
            ]}
          /> 
          <button className={`${eventForm.location_data.location == "Lieu" ? styles.btn_active : ''} ${styles.btn_default}`} onClick={handleSelectLocation} id="Lieu">Lieu</button>
          <button className={`${eventForm.location_data.location == "En_ligne" ? styles.btn_active : ''} ${styles.btn_default}`} onClick={handleSelectLocation} id="En_ligne">En ligne</button>
          <button className={`${eventForm.location_data.location == "A_definir" ? styles.btn_active : ''} ${styles.btn_default}`} onClick={handleSelectLocation} id="A_definir">A définir</button>
        </div>
        <div className={styles.form_group}>  
          
        { (eventForm.location_data.location == "Lieu" || eventForm.location_data.location == "En_ligne") && (
          <>
            <label htmlFor="">
              { eventForm.location_data.location == "Lieu" ? "Entrer l'adresse" : ""}
              { eventForm.location_data.location == "En_ligne" ? "Lien URL" : ""}              
            </label>
            <input type="text" name="" id="" placeholder="" 
              value={eventForm.location_data.locationMeta}
              onChange={(e)=> handleInputEventChange(e, "location_data","locationMeta")} 
            />               
            {formErrors['location_data.locationMeta'] && <span style={{display:'block'}} className="text-error">{formErrors['location_data.locationMeta']}</span>}            
          </>
        )}
          
          {eventForm.location_data.location == "Lieu" && (
            <>
            <span>Ou selectionner un lieu</span>          
            <Select 
              defaultValue={eventForm.location_data.location_id || "0"}
              style={{width: '50%'}}
              options={[{value:"0",label:"Lieu"},...locations.map((loc)=> ({value: loc.id, label: loc.name})), {value:'autre_lieu',label:"Autre"}]}
              onChange={handleLocationChange}           
            />      
            {formErrors['location_data.location_id'] && <span style={{display:'block'}} className="text-error">{formErrors['location_data.location_id']}</span>}            
            </>
          )} 
            {/* <select name="" id="" onChange={handleLocationChange}>
              <option value="">Lieu</option>
              {locations.map((loc)=> {
                if(eventForm.location_data.location_id == loc.id){
                  return <option value={loc.id} key={loc.id}  selected >{loc.name}</option>                
                } else {
                  return <option value={loc.id} key={loc.id} >{loc.name}</option>                
                }
              })}
            </select> */}
            {/* <ErrorBoundary fallback={<div>Something went wrong</div>}> 
            {showMap &&  <GooglePlacesAutocomplete
              //apiKey="AIzaSyCunMwmsul0Ar6es8Pd43hZiBFfMe-mYlw"                        
              selectProps={{
                //value,
                // onChange: (e)=>handleInputEventChange(e, "location_data","locationMeta",e.label)
                onChange: async (e)=>{
                  let val = e.label
                  // console.log(val);
                  const results = await geocodeByAddress(val)
                  // console.log(results)
                  const {lat, lng } = await getLatLng(results[0]);
                  // console.log('Successfully got latitude and longitude', { lat, lng });      
                  setDefaultProps((old)=>({...old,'zoom': 14, 'center':{'lat': lat, 'lng': lng}}))  
                  setEventForm((old)=>{
                    return {...old, 'location_data': {
                        ...old['location_data'], 
                        latitude: lat , 
                        longitude: lng,
                        locationMeta: val
                      }}
                  })        
                }
              }} 
            /> }
            </ErrorBoundary> */}
            {/* <div style={{height:'300px'}}>              
              <GoogleMapReact
                bootstrapURLKeys={{ key: "AIzaSyCunMwmsul0Ar6es8Pd43hZiBFfMe-mYlw", libraries:['places'] }}                
                //defaultCenter={defaultProps.center}
                center={defaultProps.center}
                //defaultZoom={defaultProps.zoom}
                zoom={defaultProps.zoom}
                onDrag={handleMapDrag}
              >
                <AnyReactComponent
                  lat={defaultProps.center.lat}
                  lng={defaultProps.center.lng}
                  text="My Marker"
                /> 
              GoogleMapReact>
            </div> */}
            
        </div>
        <div className={styles.form_group}>
          <label htmlFor="">Capacité maximal</label>
          <input type="number" name="" id="" placeholder="Capacité maximal (-1 pour illimité)" 
            value={eventForm.maximum_capacity}
            onChange={(e)=> handleInputEventChange(e, "maximum_capacity")} />
        </div>
          </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
          <Accordion.Header><h2 className={`${styles.timing_title} ${styles.title_icons}`}>Date et l'heure</h2></Accordion.Header>
          <Accordion.Body>
        
        <p>Informez les spectateurs du début et de la fin de votre événement afin qu'ils puissent planifier leur participation.</p>
        <div className={styles.form_inline_group}>
          <button className={`${eventForm.date_time.frequency == "once" ? styles.btn_active : ''} ${styles.btn_default}`} onClick={handleSelectTiming}  id="once">Événement unique</button>
          <button className={`${eventForm.date_time.frequency == "recurrent" ? styles.btn_active : ''} ${styles.btn_default}`} onClick={handleSelectTiming} id="recurrent">Événement récurrent</button>
        </div>
        
        <div className={`${styles.form_inline_group} ${styles.timing_form}`}>
          <div className={`${styles.form_group}`}>
            <label htmlFor="">l'événement commence</label>
            {/* <input type="date" name="" id="" 
            value={eventForm.date_time.date_start}
            onChange={(e)=> handleInputEventChange(e, "date_time","date_start")}/> */}
            <DatePicker 
            disabledDate={disabledDate}
            value={eventForm.date_time.date_start ? dayjs(eventForm.date_time.date_start, "DD-MM-YYYY") : ""}
            format={"DD-MM-YYYY"} 
            onChange={(e,date)=> handleInputEventChange(e, "date_time","date_start", date)}          
            /> 
            {formErrors['date_time.date_start'] && <span style={{display:'block'}} className="text-error">{formErrors['date_time.date_start']}</span>}

          </div>
          <div className={`${styles.form_group}`}>
            <label htmlFor="">Heure de début (GMT)</label>
            {/* <input type="time" name="" id="" 
            value={eventForm.date_time.time_start}
            onChange={(e)=> handleInputEventChange(e, "date_time","time_start")}/> */}
            <TimePicker format="HH:mm" minuteStep={15} needConfirm={false}
            value={eventForm.date_time.time_start ? dayjs(eventForm.date_time.time_start, "HH:mm") : ""}
            onChange={(e,val)=> handleInputEventChange(e, "date_time","time_start",val)} />
            {formErrors['date_time.time_start'] && <span style={{display:'block'}} className="text-error">{formErrors['date_time.time_start']}</span>}
          </div>
        </div>
        <div className={`${styles.form_inline_group} ${styles.timing_form}`}>
          <div className={`${styles.form_group}`}>
            <label htmlFor="">l'événement se termine</label>
            {/* <input type="date" name="" id=""
            value={eventForm.date_time.date_end}
             onChange={(e)=> handleInputEventChange(e, "date_time","date_end")} /> */}
             <DatePicker 
            disabledDate={disabledDate}
            value={eventForm.date_time.date_end ? dayjs(eventForm.date_time.date_end, "DD-MM-YYYY") : ""}
            format={"DD-MM-YYYY"} 
            onChange={(e,date)=> handleInputEventChange(e, "date_time","date_end", date)}          
            /> 
            {formErrors['date_time.date_end'] && <span style={{display:'block'}} className="text-error">{formErrors['date_time.date_end']}</span>}
          </div>
          <div className={`${styles.form_group}`}>
            <label htmlFor="">Heure de fin (GMT)</label>
            {/* <input type="time" name="" id="" step="900"
            value={eventForm.date_time.time_end}
            onChange={(e)=> handleInputEventChange(e, "date_time","time_end")} /> */}
            <TimePicker format="HH:mm" minuteStep={15} needConfirm={false}
            value={eventForm.date_time.time_end ? dayjs(eventForm.date_time.time_end, "HH:mm"): ""}
            onChange={(e,val)=> handleInputEventChange(e, "date_time","time_end",val)} />
            {formErrors['date_time.time_end'] && <span style={{display:'block'}} className="text-error">{formErrors['date_time.time_end']}</span>}
          </div>
        </div>

        {/* <div className={styles.form_group}>         
          <label htmlFor="afficher_heure_debut"> 
          <input type="checkbox" id="afficher_heure_debut" />
          
          Afficher l'heure de début.</label>
        </div>
        <div className={styles.form_group}> 
         
          <label htmlFor="afficher_heure_fin"> <input type="checkbox" id="afficher_heure_fin" />Afficher l'heure de fin.</label>
        </div> */}
        <div className={styles.form_inline_group}>
          {/* <div className={styles.form_group}>
            <label htmlFor="">Fuseau horaire</label>
            <select name="" id="">
              <option value="">(GMT +0800) heure du sénégal</option>
            </select>
          </div> */}
          {/* <div className={styles.form_group}>
            <label htmlFor="">langue de la page d'événement</label>
            <select name="" id="">
              <option value="">Français</option>
            </select>
          </div> */}
        </div>
          </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        <div className={``}>
            {Object.keys(formErrors).length > 0  && (
              <p class="text-error">
                <FontAwesomeIcon icon={faCircleExclamation} style={{color: 'red'}} />
                Veuillez verifier le formulaire et recommencer
              </p>
            )}    
            <div className={`${styles.form_inline_group} form_pull_right_cta`}>
              <button onClick={handleEventCreate} className="btn eventkati_btn">
              Sauvegarder et continuer</button>     
            </div>     
        </div>   
      </div>
      <TransportationFormModal 
        showCreateTransportationModal={showCreateTransportationModal} 
        setShowCreateTransportationModal={setShowCreateTransportationModal}
        currentTransport={{}}
      />
      <LocationFormModal 
        showCreateLocationModal={showCreateLocationModal}
        setShowCreateLocationModal={setShowCreateLocationModal}
        currentLocation={{}}
      />
    </>
  )
}