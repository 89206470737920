import { useState, useEffect } from 'react';
import 'app/admin.css'

import ApiConfig from 'app/ApiConfig';

import LocationFormModal from './LocationFormModal';

import { Button, Modal, Dropdown } from 'react-bootstrap';
import { Table } from 'antd';
import DeleteEntityModal from 'app/components/DeleteEntityModal';


export default function Locations () {
  // SEARCH
  const [nameSearch, setNameSearch] = useState("");
  const [dateStartSearch, setDateStartSearch] = useState("");
  const [dateEndSearch, setDateEndSearch] = useState("");
  const [columns, setColumns] = useState([]);
  const [tickets, setTickets] = useState({});

  const [currentLocation, setCurrentLocation] = useState(null);
  // CREATE
  const openCreateLocationModal = (e) => {
    e.preventDefault();
    setCurrentLocation(null);
    setShowCreateLocationModal(true)
  }

  // UPDATE
  const openUpdateEventModal = (e, record) => {
    setCurrentLocation(record)
    setShowCreateLocationModal(true)
  }

  // DELETE
  const [showDeleteEntityModal, setShowDeleteEntityModal] = useState(false)
  const [toDeleteEntity, setToDeleteEntity] = useState({});
  const openDeleteEntityModal = (e, record) => {
    e.preventDefault();
    setToDeleteEntity(record);
    setShowDeleteEntityModal(true);
  }
  const onHideDeleteEntityModal = (e) => {
    setShowDeleteEntityModal(false)
  }

  
  // get column
  useEffect(()=>{
    const columns_builder = [
      {
        // title: ( 
        //   <div className={styles.form_group}>
        //     <strong>Titre de l'event</strong>
        //     <input type="text" value={nameSearch} onChange={e=>setNameSearch(e.target.value)} placeholder='Rechercher par nom' />
        //   </div>
        // ),
        title: (<strong>Titre de l'event</strong>),
        render: (_,row) => { 
          //let url = `/events/list/${row.id}`
          //return <Link href={url}>{row.title}</Link> 
          return row.name
        },
      }, 
      {
        title: 'Description',
        render: (_,row) => row.description,
      }, 
      {
        title: 'Action',
        render: (_,row) => (
        <Dropdown>
          <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            Paramètres
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item onClick={e=>openUpdateEventModal(e, row)}>Modifier</Dropdown.Item>
            <Dropdown.Item onClick={e=>openDeleteEntityModal(e, {...row, title: row['name']})}>Supprimer</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        ),
      }
      
    ];

    setColumns(columns_builder);
  },[])

  useEffect(()=>{
    let filters = {};
    if(nameSearch)
      filters['title'] = nameSearch

    if(dateStartSearch){
      filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
      filters['date_time']['date_start'] = dateStartSearch
    }

    if(dateEndSearch){
      filters['date_time'] = filters['date_time'] != undefined ? {...filters['date_time']} : {}
      filters['date_time']['date_end'] = dateEndSearch
    }

    

    console.log('FINALE FILTER')
    console.log(filters);
    console.log(encodeURI(JSON.stringify(filters)))
  },[nameSearch, dateStartSearch, dateEndSearch])



  // DATATABLE
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(10);
	const [page, setPage] = useState(1);
	const [showCreateLocationModal, setShowCreateLocationModal] = useState(false);

	const fetchLocations = async page => {
		setLoading(true);
    console.log("CURRENT PER PAGE", perPage)
    let start = (page - 1) * perPage ;
    let end = page * perPage;

		//const response = await fetch(`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`,{
		const response = await fetch(`${ApiConfig.locations}?_start=${start}&_end=${end}`,{
      headers:{
        'Content-Type':'application/json'
      }
    });
    const locations = await response.json();

    console.log(locations);
		setData(locations.data);
		setTotalRows(locations.total);
		setLoading(false);
	};

	const handlePageChange = page => {
		fetchLocations(page);
	};

	const handlePerRowsChange = async (newPerPage, page) => {
		setPerPage(newPerPage);
    //fetchUsers(page);
	};

  const handleClose = (e) => {
    //e.preventDefault();
    setShowCreateLocationModal(false);
  };

	useEffect(() => {
		fetchLocations(page); // fetch page 1 of users
		
	}, [perPage, page]);

	return (
    <>
        <Button className="btn btn-primary" onClick={openCreateLocationModal}>Ajouter un lieu</Button>
        
        <Table 
          columns={columns}
          dataSource={data}
          loading={loading}
          pagination={{
            pageSize: perPage,
            total: totalRows,
            onChange: (page)=>{
              setPage(page)
            }
          }}
        />
       <LocationFormModal 
        showCreateLocationModal={showCreateLocationModal}
        setShowCreateLocationModal={setShowCreateLocationModal}
        currentLocation={currentLocation}
      />
      <DeleteEntityModal show={showDeleteEntityModal} onHide={onHideDeleteEntityModal} entity={toDeleteEntity}/>

    </>
	);
};
