import Footer from 'app/components/Footer'
import Navbar from 'app/components/Navbar'

import ErrorCheck from 'app/components/error-check/ErrorCheck'

export default function PaymentError() {
  return (
    <>
    <Navbar />
    <div className="payment_feedback">        
        <ErrorCheck />
        <h1>Echec du paiement, veuillez reessayer.</h1>
      </div>
    <Footer />
    </>
  )
}
