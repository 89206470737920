import styles from "./single-event-card.module.css";
import { Link, useNavigate } from "react-router-dom";
import AppConfig from "app/AppConfig";
import moment from 'moment';

import { Badge } from 'react-bootstrap';

export default function SingleEventCard({event}) {

  const navigate = useNavigate();

  return (
      <div className={styles.single_event_card}>
        {/* <img
          loading="lazy"
          srcSet={event.image_link}
          className="img"
        /> */}
        <div className={`event_card_image`} onClick={(e)=>navigate(`/events/${event.id}`,{reloadDocument: true})}
         style={{backgroundImage: `url(${event.image_link})`}}></div>
        <div className={styles.div2}>
          <div className={styles.div3}>
            <Link to={`/events/${event.id}`} state={{event_id: event.id}} preventScrollReset={true}>{event.title}</Link>
          </div>
          <div className={styles.div4}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/d889b4b8ea940243c8bf2a8a6f6076c4f259742129bb4b6c068a16798bdfbd56?apiKey=52aeea5753104c6b836e329f8e3d046f&"
              className={styles.img2}
            />
            <div className={styles.div5}>{moment(event.date_time?.date_start).format('D MMMM YYYY')}</div>
          </div>
          <hr className={`hr_lightgray`} style={{margin: 0}}/>
          <div className={styles.div6}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/38fe2490f636f46cd0ea2eb18d2ba9e61c2441de6126cf8ddeacff5fcee85416?apiKey=52aeea5753104c6b836e329f8e3d046f&"
              className={styles.img4}
            />
            <div className={styles.div7}>{event.location_data?.locationMeta}</div>
          </div>
          <div className={styles.div8}>
            {/* <div className={styles.div9}>FCFA</div> */}
            <div className={styles.div10}>
              {event.ticket_infos?.map((ticket)=>{
              let type = ticket.type;
              if(type === AppConfig.donate_cta)
                return <span key={ticket.id}><Badge bg="warning">{ticket.title}</Badge><br/></span>
              if(type === AppConfig.participate_cta)
                return <span key={ticket.id}><Badge bg="primary">{ticket.title}</Badge><br/></span>
              if(type === AppConfig.pay_cta)
                return <span key={ticket.id}><Badge bg="success">{ticket.title}({ticket.price} CFA)</Badge><br/></span>

              
            })}</div>
          </div>
          <div className={styles.div11}>{event.owner_name}</div>
          <div className={styles.div12}>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6b98bfbe3f5c6007b5a21688ca5e0bf32e45172082899254d5522f64f6d7f8e?apiKey=52aeea5753104c6b836e329f8e3d046f&"
              className={styles.img5}
            />
            <div className={styles.div13}>2k abonnés</div>
          </div>
        </div>
      </div>
  )
}
