import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import MessageForm from './MessageForm';

//  //  MODAL
//  const [showMessageFormModal, setShowMessageFormModal] = useState(false);
  
//  // CREATE
//  const openMessageFromModal = (e) => {
//    //e.preventDefault();
//    setShowMessageFormModal(true)
//  }
export default function MessageFormModal({ showMessageFormModal, setShowMessageFormModal, planner_id, sender_id}) {
  // CLOSE
  const handleClose = (e) => {
    // e.preventDefault();
    setShowMessageFormModal(false);
   };

  return (
    <Modal show={showMessageFormModal} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>Nouveau message</Modal.Title>
    </Modal.Header>
    <Modal.Body>
          <MessageForm planner_id={planner_id} sender_id={sender_id} onHide={handleClose}/>
    </Modal.Body>          
  </Modal>  
  )
}
