import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import CreateLocationForm from './CreateLocationForm';
export default function LocationFormModal({showCreateLocationModal, setShowCreateLocationModal, currentLocation }) {
  
  const handleClose = () => {
    setShowCreateLocationModal(false);
  }
  
  return (
    <Modal show={showCreateLocationModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un lieu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateLocationForm currentLocation={currentLocation}/>        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>          
        </Modal.Footer>
      </Modal>  
  )
}
