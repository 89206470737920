"use client";

import styles from "./create_event.module.css";
import { FileUploader } from "react-drag-drop-files";

import { useEffect, useState } from "react";
import { useRecoilState } from 'recoil';
import { createEventForm } from 'app/states/event_state';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


const fileTypes = ["JPEG", "PNG", "GIF", "JPG","SVG"];

export default function CreateEvent2({prev, next, display}) {
  

  const [eventForm, setEventForm ] = useRecoilState(createEventForm);

  const [resumeData, setResumeData] = useState("");
  //
  const [hasImage, setHasImage] = useState(null);
  const handleChange = (file) => {
    setEventForm((old)=>{
      return {...old, file: file}
    })
    setHasImage(URL.createObjectURL(file));    
  };
  //
  const handleInputEventChange = (e, field, subfield = null) => {
    setEventForm((old,curr)=> {
      if(subfield) {
        let obj = {
          [field]:{
            ...old[field],
            [subfield]: e.target.value
          }
        };
        return {...old, ...obj}
      } else {      
        return {...old , [field]: e.target.value}
      }
    })
  }
  const handleEditorChange = (field, value) => {
    setEventForm((old)=>{
      return {...old, [field]:value}
    })
  }
  
  const handleEventCreate = (e) => {
    e.preventDefault();    
    next();    
  }
  return (
    <>
      <div className={`${display != 'update_modal' ? styles.form_section : ""}`}>
      <section id="file_upload_section">
        <h2 className={``}>Image de l'événement principal</h2>
        <p>Il s'agit de la première image que les participants verront en haut de votre annonce.</p>
        <FileUploader
          multiple={false}
          handleChange={handleChange}
          name="file"
          types={fileTypes}
        />
        {hasImage && <img src={hasImage} className={styles.preview_image}/>}
      </section>
      <section>
        <h2 className={``}>Résumé</h2>
        <p>Attirez l'attention des gens avec une courte description de votre événement. Les participants le verront en haut de la page de votre événement. (140 caractères maximum). Voir des exemples.</p>        
        <div className={styles.form_group}>
          <label htmlFor="">Résumé</label>
          <input type="text" value={eventForm.resume} onChange={e=>handleInputEventChange(e,'resume')} />
        </div>   
        {/* <CKEditor
            editor={ ClassicEditor }
            data={eventForm.summary}       
            config={{
              //removePlugins: [ 'Image' ]
              removePlugins: [ 'EasyImage' ],
            }}    
            onChange={ ( event, editor ) => {
                handleEditorChange('summary', editor.getData());               
            } }
            onReady={ ( editor ) => {
              editor.ui.view.editable.element.style.minHeight = "300px";
            } }           
        />      */}
      </section>
      <section>
        <h2 className={``}>Description</h2>
        <p>Add more details to your event like your schedule, sponsors, or featured guests. Learn more</p>
        {/* <div className={styles.form_group}>
          <label htmlFor="">Description</label>
          <textarea name="" id="" cols="30" rows="10" value={eventForm.description} onChange={e=>handleInputEventChange(e,'description')}></textarea>
        </div>      */}

        <CKEditor
            editor={ ClassicEditor }
            data={eventForm.description}       
            config={{
              //removePlugins: [ 'Image' ]
              removePlugins: [ 'EasyImage' ],
            }}    
            onChange={ ( event, editor ) => {
                handleEditorChange('description', editor.getData());               
            } }
            onReady={ ( editor ) => {
              editor.ui.view.editable.element.style.minHeight = "300px";
            } }
            // onBlur={ ( event, editor ) => {
            //     //console.log( 'Blur.', editor );
            // } }
            // onFocus={ ( event, editor ) => {
            //     //console.log( 'Focus.', editor );
            // } }
        />
        
        <div className={`${styles.form_inline_group} form_pull_right_cta`}>
          <button onClick={handleEventCreate} className="btn eventkati_btn">Sauvegarder et continuer</button>
        </div>
      </section>
      </div>
    </>
  )
}