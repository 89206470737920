'use client';

//import 'app/admin.css'

import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil';
import { createEventSteps } from 'app/states/event_state';

import { Modal } from 'react-bootstrap';

import CreateEvent1 from './CreateEvent1';
import CreateEvent2 from './CreateEvent2';
import CreateEvent3 from './CreateEvent3';
import CreateEvent4 from './CreateEvent4';

const steps = [
    {
      id: '1',
      name: 'Informations de bases',
      fields: ['firstName', 'lastName', 'email']
    },
    {
      id: '2',
      name: 'Details',
      fields: ['country', 'state', 'city', 'street', 'zip']
    },
    {
      id: '3',
      name: 'Billets',
      fields: ['country', 'state', 'city', 'street', 'zip']
    },
    { id: '4', name: 'Publier' }
  ]

export default function EventGenerateModal({show, onHide}) {
  
  
  const [currentStep, setCurrentStep] = useRecoilState(createEventSteps);
  // const [eventForm, setEventForm] = useRecoilState(createEventForm);
  
  // const navigate = useNavigate();

  // let user = null;
  // useEffect(()=>{
  //   user = getUser() ;
  // },[]);

  //setup
  useEffect(() => {
    //document.getElementById("contentadmincontainerscroller").scroll(0,0)    
    console.log("should scroll top")
  }, [currentStep]);
  
  const next = (e) => {  
    console.log("next clicked");
   // e.preventDefault();
   // document.getElementById("contentadmincontainerscroller").scroll(0,0)  
    if (currentStep < steps.length ) {
      setCurrentStep(step => step + 1)
    }
  }
  const prev = (e) => {  
    e.preventDefault();
    if (currentStep > 0 ) {
      setCurrentStep(step => step - 1)
    }
  }

  return (   
    <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier l'évènement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentStep === 0 && (
            <CreateEvent1 next={next} display="update_modal"></CreateEvent1>
          )}

          {currentStep === 1 && (
            <CreateEvent2 next={next} prev={prev} display="update_modal"></CreateEvent2>
          )}

          {currentStep === 2 && (
            <CreateEvent3 next={next} prev={prev}  display="update_modal"/>
          )}
          {currentStep === 3 && (
            <CreateEvent4 next={next} prev={prev}  display="update_modal"/>
          )}
   </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          <Button variant="primary" onClick={e => handleSave(e)}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
  )
}
