import styles from './category-card.module.css'

export default function SubCategoryCard({category, currentSubCat, setCurrentSubCat}) {


  const is_active = (currentSubCat && (currentSubCat.id !== category.id)) ? styles.inactive : '';

  return (
        <div className={`${styles.home_categories_list_item} subcategory_card `} onClick={()=>setCurrentSubCat(category)} >
        <img
          loading="lazy"
          src={category.icon_link}
          className={styles.img5}
        />{" "}
      
        <p className={`${styles.home_categories_list_item_title} ${is_active}`}>{category.name}</p>
        </div>
  )
}
