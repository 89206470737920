import styles from './category-card.module.css'
import { Link } from 'react-router-dom'
export default function CategoryCard({category,isSubcat}) {
  return (
        <Link to={!isSubcat ? `/events/category/${category.id}` : ``} className={styles.home_categories_list_item} >
        <img
          loading="lazy"
          src={category.icon_link}
          className={styles.img5}
        />{" "}
      
        <p className={styles.home_categories_list_item_title}>{category.name}</p>
        </Link>
  )
}
