"use client";

import styles from "./create_event.module.css";

import {useState} from 'react';
import { useRecoilState , useResetRecoilState} from 'recoil';
import { createEventForm } from 'app/states/event_state';
import ApiConfig from "app/ApiConfig";
import dayjs from "dayjs";

import { useNavigate } from "react-router-dom";
import { Spin, DatePicker, TimePicker } from "antd";

const objFields = ['location_data','date_time','publish_data','transportation_data'];
const ticketFields = ['sales_date']

export default function CreateEvent4({prev, next, display}) {
  //setup
  const [eventForm, setEventForm ] = useRecoilState(createEventForm);
  const resetEvent = useResetRecoilState(createEventForm)
  const navigate = useNavigate()
  //
  const handleInputChange = (e, field, subfield = null,value=null) => {
    let val = e?.target?.value || value;
    setEventForm((old,curr)=> {
      if(subfield) {
        let obj = {
          [field]:{
            ...old[field],
            [subfield]: val
          }
        };
        return {...old, ...obj}
      } else {      
        return {...old , [field]: val}
      }
    })
  }
  const handlePublishChange = (e, field, value)=> {      
    setEventForm((old)=>{
      return {...old, 'publish_data': {...old['publish_data'],[field]: value} }
    })
  }
  //
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf('day');
  };
  const [isFormSubmitting, setisFormSubmitting] = useState(false);

  const handleGenerateEvent = async (e) => {
    e.preventDefault();
    setisFormSubmitting(true);
    console.log(eventForm);
    let formData = new FormData()

    for(var field in eventForm){
      if(field == "tags"){
        let tags = eventForm[field];
        for(let i = 0; i < tags.length; i++){
          formData.append('tags[]', tags[i].trim());
        }
      } else if(field=='ticket_infos'){
        for(let j = 0; j < eventForm[field].length;j++ ) {
          let ticket = eventForm[field][j];
          let ticket_fd = new FormData();
          for(let key in ticket){
            //ticket_fd.append(key, ticket[key])
            if(ticketFields.includes(key)){             
              for(let subfield in ticket[key]){
                formData.append(`ticket_infos[${j}][${key}][${subfield}]`, ticket[key][subfield])
              }
            } else{
              formData.append(`ticket_infos[${j}][${key}]`, ticket[key])
            }

          }
          // console.log('ticket_fd',ticket_fd.entries())
          // formData.append('ticket_infos[]',ticket_fd.entries())
        }
        
      } else if(objFields.includes(field)){
        for(let subfield in eventForm[field]){
          formData.append(`${field}[${subfield}]`, eventForm[field][subfield]);
        }
      } else {
        formData.append(field, eventForm[field]);
      }
      
    }
    console.log(formData);
    const submitEventReq = await fetch(ApiConfig.create_event, {
      method: "POST",
      body: formData
    });
    const responseSubmitEvent = await submitEventReq.json();

    if(responseSubmitEvent.data){
      resetEvent();
      navigate(`/events/${responseSubmitEvent.data.id}`);
    } else { // error
      alert(JSON.stringify(responseSubmitEvent));

    }
  }
  
  const handleEventCreate = (e) => {
    e.preventDefault();    
    next();    
  }
  return (
    <>
      <div className={`${display != 'update_modal' ? styles.form_section : ""}`}>
      <section>
        <h2 className={``}>Publiez votre événement</h2>
        <br />

        <div className={`inline_text jc-start publish_flyer`}>
          <img  className={styles.preview_image} alt=""
          src={eventForm.file ? URL.createObjectURL(eventForm.file) : "/images/publish_event.jpg"} />
          <div>
            <strong>{eventForm.title}</strong>
            <p>{eventForm.date_time.date_start} {` à ${eventForm.date_time.time_start}`}</p>
            <p>{eventForm.location_data.locationMeta}</p>
          </div>
          <p>{eventForm.ticket_infos[0] && eventForm?.ticket_infos[0].prix}</p>
        </div>
      </section>
      <section className="inline_text jc-start publish_block">
          <div className="publish_form">
            <div>            
              <strong>Qui peut voir votre événement ?</strong>
              <p>
                <label><input type="radio" name="public" id="" value="public" defaultChecked onChange={e=>handlePublishChange(e,"is_public","public")}/>
                Publique  <br /><em>partagé sur KATI360 et d'autres sites</em>
              </label>
              </p>
              <p>
                <label><input type="radio" name="public" id="" value="private" onChange={e=>handlePublishChange(e,"is_public","private")} />
                  Privé <br /><em>disponible uniquement pour un public sélectionné</em>
                </label>
              </p>
            </div>
            <div>            
              <strong>Quand devons-nous publier votre événement ?</strong>
              <p>
                <label><input type="radio" name="publish_time" id="" defaultChecked onChange={e=>handlePublishChange(e,"publish_time","now")}/>
                  Publier maintenant  
                </label>
              </p>
              <p>
                <label><input type="radio" name="publish_time" id="" onChange={e=>handlePublishChange(e,"publish_time","later")}/>
                  Planifier plus tard 
                </label>
              </p>
              <div className={`${styles.form_inline_group} ${styles.timing_form}`}>
                <div className={`${styles.form_group}`}>
                  <label htmlFor="">Date de début</label>
                  {/* <input type="date" name="" id="" 
                  value={eventForm.publish_data?.date_start}
                  onChange={(e)=> handleInputEventChange(e, "publish_data","date_start")}/> */}
                  <DatePicker 
                  disabledDate={disabledDate}
                  value={eventForm.publish_data?.date_start ? dayjs(eventForm.publish_data.date_start, "DD-MM-YYYY") : ""}
                  format={"DD-MM-YYYY"} 
                  onChange={(e,date)=> handleInputChange(e, "publish_data","date_start", date)}        
                  /> 
                </div>
                <div className={`${styles.form_group}`}>
                  {/* <label htmlFor="">Heure de début</label>
                  <input type="time" name="" id="" 
                  value={eventForm.publish_data?.time_start}
                  onChange={(e)=> handleInputEventChange(e, "publish_data","time_start")}/> */}
                   <TimePicker format="HH:mm" minuteStep={15} needConfirm={false}
                    value={eventForm.publish_data?.time_start ? dayjs(eventForm.publish_data.time_start, "HH:mm") : ""}
                    onChange={(e,val)=> handleInputChange(e, "publish_data","time_start",val)} 
                  />
                </div>
              </div>
            </div>
            
          </div>
          <div className="publish_conseil">
            <p className="publish_conseil_title"><img src="/icons/conseils.png" alt=""/>Conseils avant de publier</p>
            <ul className="conseil_list">
              <li>
                <a href="">Créez des codes promotionnels pour votre événement</a>
               </li>
               <li>
                <a href="">Personnalisez votre bon de commande</a>
               </li>
               <li >
                <a href="">Add this event to a collection to increase visibility</a>
              </li>
            </ul>

          </div>
      </section>
      <button className="btn eventkati_btn" onClick={handleGenerateEvent} disabled={isFormSubmitting}>Publier{isFormSubmitting && <Spin />}</button>
      </div>
    </>
  )
}