import React from 'react'
import { Table, Badge } from 'react-bootstrap'

export default function Billeterie({row}) {
  return (
    <Table bordered hover>
      <thead>
        <tr>
        <th>Ticket</th>
        <th><Badge bg="warning">Disponible</Badge></th>
        <th><Badge bg="success">Vendu</Badge></th>
        <th><Badge bg="secondary">Composté</Badge></th>
        </tr>
      </thead>
      <tbody>
        { row.billeterie.tickets.map((ticket)=>{
            return (<tr key={ticket.id}>
            <th>{ticket.title}</th>
            <th><Badge bg="warning">{ticket.quantity}</Badge></th>
            <th><Badge bg="success">{ticket.quantity ? (parseInt(ticket.quantity) - parseInt(ticket.quantity_left)) : 0}</Badge></th>
            <th><Badge bg="secondary">{ticket.compost}</Badge></th>                            
          </tr>)
        }) }
      </tbody>
    </Table>
  )
}
