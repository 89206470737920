import styles from "./category.module.css";

import ApiConfig from "app/ApiConfig";
import { useState, useEffect } from 'react';

import Navbar from "app/components/Navbar";
import SingleEventCard from "app/components/single-event-card/single-event-card";
import OrganiseurHomeCard from "app/components/organiseur-home-card/organiseur-home-card";
import SubCategoryCard from "app/components/category-home-card/subcategory-card";
import Footer from "app/components/Footer";

import { useParams, useNavigate } from "react-router-dom";
import { Alert , Button} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavbarSize } from "app/utils/useNavbarSize";

export default function EventsByCategory() {

  const {id:category_id} = useParams()
  const [eventFilter, setEventFilter] = useState({'category_id':category_id})
  const navigate = useNavigate();
  const navbarSize = useNavbarSize();
  // users
  const [users, setUsers] = useState([]);
  useEffect(()=>{
    const fetchUsers = async () => {
      const res = await fetch(ApiConfig.agents);
      const usersRes = await res.json();
      setUsers(usersRes.data);
    }
    fetchUsers().catch(e=>console.log(e));
  },[])

  // categories
  const [category, setCategory] = useState([]);
  useEffect(()=>{
    const fetchCategory = async () => {
      const res = await fetch(`${ApiConfig.categories}/${category_id}`);
      const catRes = await res.json();      
      setCategory(catRes.data);
    }
    fetchCategory().catch(e=>console.log(e));
  },[])

  // events
  const [events, setEvents] = useState([]);
  useEffect(()=>{
    const fetchEvents = async () => {
      const res = await fetch(`${ApiConfig.events}??_start=${0}&_end=${perPage}&filtre=${encodeURIComponent(JSON.stringify(eventFilter))}`);
      const eventsRes = await res.json();
      setEvents(eventsRes.data);
    }
    fetchEvents().catch(e=>console.log(e));
  },[eventFilter])

  // event pagination
  const [canLoad, setCanLoad] = useState(true);
  const [eventPage, setEventPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  //
  const loadMoreEvent = (e)=>{
    e.preventDefault();
    setEventPage(old=> old +1);
  }
   //pagination load
   useEffect(()=>{
    if(eventPage > 1 && canLoad) {
      const fetchEvents = async () => {
        let url = ApiConfig.events;
        
        // pagination
        let start = (eventPage - 1) * perPage ;
        let end = eventPage * perPage;
        url += `?_start=${start}&_end=${end}`

        // filtres
        let filter = {...eventFilter};
       
        if( Object.keys(filter).length){
          let filter_url = encodeURIComponent(JSON.stringify(filter));
          url = `${url}&filtre=${filter_url}`
        }          
        // request
        const res = await fetch(url);
        const eventsRes = await res.json();
        if(eventsRes.data.length == 0 ) { // no more items to load
          setCanLoad(false)
        } else { // received items from request
          setEvents((old)=> {
            //console.log("EVENT :")
            let current = [...old];
            for(let i = 0; i< eventsRes.data.length; i++) {
              let event = eventsRes.data[i];
              // do not add already existing event
              if(!current.some((elem)=> event.id == elem.id)) {
                current.push(event)
              }
            }
            //console.log(current)  ;
            return current
          });
        }
      }
      fetchEvents().catch(e=>console.log(e));
    }
  },[eventPage])

  //subcategories
  const [currentSubCat, setCurrentSubCat] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [isSubcatEmpty, setIsSubcatEmpty] = useState(false);
  useEffect(()=>{
    const fetchSubcategories = async () => {
      const subReq = await fetch(`${ApiConfig.sub_categories}${category_id}`);
      const sub = await subReq.json();
      if(!sub.data.length) {
        setIsSubcatEmpty(true);
      }
      setSubCategories(sub.data);
    }
    fetchSubcategories().catch(e=>console.log(e));
  },[])

  useEffect(()=>{
    // console.log("SUB CAT SELECTED")
    // console.log(currentSubCat);
    if(currentSubCat){
      setEventFilter((old)=>({...old, 'subcategory_id':currentSubCat.id}))
    } else {
      
      setEventFilter(old=>{
        if('subcategory_id' in old)
          delete old['subcategory_id']
        return  {...old}
      })
    }
  },[currentSubCat])


  return (
    <>
      <div className={styles.div}>
        <Navbar />
        <div style={{paddingTop: navbarSize?.height}}></div>
        <div className={` ${styles.herosection} `} style={{backgroundImage: `url("${category.image_link}")`}}>       
          <div className={styles.hero_title}>
            <h1 className={styles.div13}>
              {category.name}
            </h1>{" "}
            {/* <p className={`${styles.div14} btn-fit`}>Trouvez votre prochain événement</p> */}
          </div>
        </div>{" "}
        <div className={`${styles.div15} page_section`}>
          <Button className={'get_back_btn'}  onClick={() => navigate(-1)}><FontAwesomeIcon icon={faCircleLeft}/>Retour à l'accueil</Button>
          <div className={styles.section_title}>Découvrez les plus populaires</div>{" "}
          {currentSubCat && <p onClick={e=>setCurrentSubCat(null)}><Alert className='selected_category_alert' message={currentSubCat.name} type="info" showIcon icon={<FontAwesomeIcon icon={faCircleXmark} />} /></p>}
          <div className={styles.home_categories_list}>
            {isSubcatEmpty && <p>Aucune sous catégorie pour <strong>{category.name}</strong></p>}
            {subCategories.map((subcat)=>{
              return (               
                  <SubCategoryCard category={subcat} key={subcat.id} currentSubCat={currentSubCat} setCurrentSubCat={setCurrentSubCat} />          
              )
            })}
          </div>{" "}
          <div className={styles.section_title}>événements</div>{" "}
          <div className={styles.events_list}>
          {events.map((event)=>{
              return (
                <SingleEventCard  key={event.id} event={event}/>            
              )
            })}
          {events.length == 0 && <p>Aucun évènement</p>}
          </div>{" "}
          {events.length > 0 && <div className="load_more_row" style={{textAlign: "center"}}><button className="btn load_more_btn" onClick={loadMoreEvent} disabled={!canLoad}>Voir plus d'évènements</button></div>}
          <div>
            <div className={styles.section_title}>Organisateurs à suivre</div>{" "}
            <div className={styles.organisateurs_list}>
              {users.map((user, index)=>{
                return (
                  <OrganiseurHomeCard key={index} orga={user}/>
                )}
              )}    
            </div>
          </div>      
        </div>{" "}        
      </div>{" "}
     <Footer />
    </>
)};