import React from 'react'
import { Select } from 'antd'
import { countries } from './countries'
import { countryState, eventFilterState } from 'app/states/event_state'
import { useRecoilState } from 'recoil'
import set from 'lodash/fp/set'
import { default_event_filter } from 'app/states/event_state'

export default function SelectCountry({display, customChange}) {
  const [eventFilter, setEventFilter ] = useRecoilState(eventFilterState)

  const handleChange = (value) => {
    console.log(`selected ${value}`);
    setEventFilter({...default_event_filter, country: {name: value} });
  };
  return (
    <>
  {display === "select" && 
   (<Select
   className='countries_select_dropdown'
      value={eventFilter.country.name}
      style={{
        width: '100&',
      }}
      onChange={customChange ? customChange : handleChange }
      options={[        
        ...countries.map((c)=>({value: c.name, label: <><span><img className="country_icon_sm" src={c.icon}/> {c.name}</span></> }))
      ]}
    />)}

  {display == 'list' && (
    <div className="home_country_list">
      {countries.map((c)=>{
      return <p onClick={e=>handleChange(c.name)} className={`country_list_item ${(eventFilter.country.name == c.name ? "active":"")}`}><img className="country_icon_sm" src={c.icon}/>{c.name}</p>
      })}
    </div>
  )}

  </>)
}
