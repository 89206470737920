import './error-check.css';

export default function ErrorCheck() {
  return (
    <div>
      <div class="circle-border"></div>
      <div class="circle">
          <div class="error"></div>
      </div>
    </div>
  )
}
